<style scoped>
    #modal-order-document #modal-order-document-container {
        display: flex;
        height: 100%;
        overflow: hidden;
    }

    #modal-order-document #modal-order-document-action-buttons-container {
        width: 23%;
        height: 100%;
        background-color: #edf0f5;
        box-sizing: border-box;
        padding: 60px 20px 30px 20px;
        overflow-y: overlay;
        scrollbar-width: thin;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    #modal-order-document #modal-order-document-action-buttons-container #order-document-status {
        width: calc(100% + 40px);
        position: relative;
        left: 0px;
        text-align: center;
        background-color: #CBD3E2;
        padding: 8px 0px;
        margin: 10px 0px;
        font-size: 10px;
        color: rgb(50, 60, 70);
        text-transform: uppercase;
        font-weight: 500;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
    }

    #modal-order-document #modal-order-document-exit-button {
        position: absolute;
        top: 10px;
        left: 10px;
        border: 1px solid rgb(80, 90, 100);
        color: rgb(80, 90, 100);
        width: 26px;
        height: 26px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #modal-order-document #modal-order-document-exit-button:hover {
        background-color: rgb(80, 90, 100);
        border-color: rgb(80, 90, 100);
        color: #ffffff;
    }

    #modal-order-document #modal-order-document-detail {
        position: relative;
        width: 77%;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    #modal-order-document #modal-order-document-action-buttons-container::-webkit-scrollbar,
    #modal-order-document #modal-order-document-detail::-webkit-scrollbar {
        width: 6px;
    }

    #modal-order-document
        #modal-order-document-action-buttons-container::-webkit-scrollbar-track,
    #modal-order-document #modal-order-document-detail::-webkit-scrollbar-track {
        background: transparent;
    }

    #modal-order-document
        #modal-order-document-action-buttons-container::-webkit-scrollbar-thumb,
    #modal-order-document #modal-order-document-detail::-webkit-scrollbar-thumb {
        background: rgb(120, 130, 140);
    }

    #modal-order-document
        #modal-order-document-action-buttons-container::-webkit-scrollbar-thumb:hover,
    #modal-order-document #modal-order-document-detail::-webkit-scrollbar-thumb:hover {
        background: rgb(100, 110, 120);
    }

    #modal-order-document #modal-order-document-action-buttons-container > div {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    #modal-order-document
        #modal-order-document-action-buttons-container
        .modal-order-document-action-button {
        width: 90%;
        margin: 10px 0px;
        max-width: 250px;
        display: flex;
        justify-content: center;
        padding: 15px 25px;
    }

    #modal-order-document :deep(.modal-container) {
        height: 100%;
        max-height: 100%;
    }

    #modal-order-document #modal-order-document-page {
        background-color: #fff;
        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 30%);
        box-sizing: border-box;
        margin: 30px auto;
        min-height: 353mm;
        padding: 40px;
        position: relative;
        width: 250mm;
    }

    #modal-order-document #modal-order-document-detail :deep(.inputeo) {
        margin: 0px 2px;
        background-color: unset;
        width: unset;
        min-width: 10px;
    }

    #modal-order-document #modal-order-document-detail :deep(.inputeo input),
    #modal-order-document #modal-order-document-detail :deep(.inputeo textarea) {
        background-color: transparent;
        font-weight: unset;
        padding: 1px;
        font-size: 10px;
        cursor: pointer;
        min-height: unset;
        max-width: 100%;
    }

    #modal-order-document #modal-order-document-detail :deep(.inputeo input):hover,
    #modal-order-document #modal-order-document-detail :deep(.inputeo textarea):hover {
        background-color: rgba(10, 10, 10, 0.1);
        border-radius: 5px;
    }

    #modal-order-document #modal-order-document-detail :deep(.inputeo::after) {
        content: unset;
    }

    #modal-order-document #order-document-body {
        margin-top: 70px;
    }

    #modal-order-document #order-document-subtitle {
        font-size: 13px;
        text-align: center;
        margin-top: 5px;
    }

    #modal-order-document .order-document-address-title {
        background-color: #0d3b70;
        color: #ffffff;
        margin-top: 25px;
        font-size: 10px;
        font-weight: bold;
        padding: 6px 15px;
        width: fit-content;
        position: relative;
    }

    #modal-order-document .order-document-address-title::after {
        position: absolute;
        right: -15px;
        top: 0;
        content: "";
        width: 0;
        height: 0;
        border-top: 7mm solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 15px solid #0d3b70;
    }

    #modal-order-document #order-document-address-main-container {
        display: flex;
        justify-content: space-between;
    }

    #modal-order-document #order-document-address-main-container > div {
        width: 48%;
    }

    #modal-order-document .order-document-address-container {
        background-color: #edf0f5;
        padding: 10px 15px;
        margin-bottom: 20px;
        position: relative;
    }

    #modal-order-document .order-document-address-line-container {
        display: flex;
        align-items: flex-start;
        font-size: 11px;
        color: rgb(50, 60, 70);
        margin: 6px 0px;
    }

    #modal-order-document
        .order-document-address-line-container:nth-child(2)
        > div:nth-child(2)
        > div:nth-child(2) {
        display: flex;
    }

    #modal-order-document .order-document-address-line-container > div:nth-child(1) {
        font-weight: bold;
        width: 80px;
        margin-right: 15px;
    }

    #modal-order-document .order-document-address-line-container > div:nth-child(1) svg {
        margin-right: 6px;
    }

    #modal-order-document .order-document-address-line-container :deep(.inputeo input) {
        text-transform: uppercase;
        font-size: 11px;
        max-width: 210px;
    }

    #modal-order-document #order-document-contact-information-container {
        display: flex;
        justify-content: space-between;
    }

    #modal-order-document #order-document-contact-information-container > div {
        width: 48%;
    }

    #modal-order-document .order-document-contact-information-title {
        font-size: 11px;
        font-weight: bold;
        padding-left: 3px;
    }

    #modal-order-document .order-document-contact-information-detail {
        font-size: 12px;
        display: flex;
    }

    #modal-order-document .order-document-table-container {
        margin: 20px 0px;
    }

    #modal-order-document .order-document-table-container table {
        width: 100%;
        border-collapse: collapse;
    }

    #modal-order-document .order-document-table-container thead {
        background-color: #0d3b70;
        color: #ffffff;
        font-size: 10px;
        text-align: left;
    }

    #modal-order-document .order-document-table-container thead th {
        padding: 4px 10px;
    }

    #modal-order-document .order-document-table-container tbody {
        background-color: #edf0f5;
    }

    #modal-order-document .order-document-table-container tbody td {
        padding: 0px 6px 5px 6px;
    }

    #modal-order-document .order-document-table-container tbody td:not(:first-child) {
        border-left: 1px solid rgb(200, 210, 220);
    }

    #modal-order-document .order-document-table-container tbody td.order-document-table-line-title {
        padding-top: 20px;
        font-weight: bold;
    }

    #modal-order-document .order-document-table-container tbody tr:last-child td {
        padding-bottom: 15px;
    }

    #modal-order-document .order-document-table-container tbody td.order-document-table-line-title :deep(textarea) {
        text-transform: uppercase;
    }

    #modal-order-document .order-document-table-container tbody td:not(.order-document-table-line-title) :deep(textarea) {
        overflow: hidden;
    }

    #modal-order-document .small-line :deep(.inputeo input),
    #modal-order-document .small-line :deep(.inputeo textarea) {
        margin: auto !important;
        font-size: 11px !important;
    }

    #modal-order-document .condition-line :deep(.inputeo input),
    #modal-order-document .condition-line :deep(.inputeo textarea) {
        font-size: 8px !important;
        margin: 0px 0px 10px 0px !important;
    }

    #modal-order-document .capital-line :deep(.inputeo input),
    #modal-order-document .capital-line :deep(.inputeo textarea) {
        margin: auto !important;
        font-size: 11px !important;
        font-weight: bold !important;
    }

    #modal-order-document .capital-line {
        position: absolute !important;
        bottom: 10px !important;
        left: 0 !important;
        right: 0 !important;
    }

    #modal-order-document .refuse-reason-line {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #FF6B00;
    }

    #modal-add-line :deep(.modal-content) {
        min-width: 420px;
    }

    #modal-add-line :deep(.checkbox-button-container) {
        width: 100%;
        padding: 0px 20px 20px 20px;
    }

    .text-before-form {
        max-width: 340px;
        margin: 0 0 40px 0;
    }

    .date-picker {
        width: fit-content;
        display: inline-block;
    }

    .date-picker :deep(input) {
        font-weight: unset !important;
        font-size: 13px !important;
        cursor: pointer !important;
        min-height: unset !important;
        width: 80px !important;
        padding: 1px 2px !important;
        text-align: center;
    }
</style>

<template>
    <div>
        <Modal :fullscreen="true" :hideIcon="true" :hideTitle="true" id="modal-order-document">
            <template v-slot:modalContent>
                <div id="modal-order-document-container" v-if="orderDocument">
                    <div id="modal-order-document-action-buttons-container">
                        <BaseButton @click="$emit('onClose');" id="modal-order-document-exit-button">
                            <template v-slot:iconBefore>
                                <font-awesome-icon :icon="['fas', 'times']" />
                            </template>
                        </BaseButton>
                        <div>
                            <BaseSelect
                                v-if="orderDocuments.length > 1"
                                label="Choix du document"
                                name="orderDocumentChoice"
                                :defaultOptions="orderDocuments.map(orderDocument => {
                                    return {
                                        label: orderDocument.name,
                                        value: orderDocument.id
                                    };
                                })"
                                @onChange="onOrderDocumentChoiceChange"
                                v-model="orderDocumentSelected"
                                :maxHeight="300"
                            />
                            <div id="order-document-status" v-if="orderDocument.status">
                                <div v-if="orderDocument.lastSendDate">
                                    Envoyé le {{ orderDocument.lastSendDate }}
                                </div>
                                <div>
                                    <span v-if="orderDocument.status === 'refused'">Refusé le</span>
                                    <span v-if="orderDocument.status === 'validated'">Validé le</span>
                                    {{ orderDocument.statusDate }}
                                </div>
                            </div>
                            <BaseButton
                                :buttonText="addLineButtonText"
                                class="blue-button modal-order-document-action-button"
                                :class="{
                                    disabled: !enableAddLineButton,
                                }"
                                @click="openAddLineModal"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'plus']" />
                                </template>
                            </BaseButton>
                            <BaseButton
                                :buttonText="chooseContactButtonText"
                                class="blue-button modal-order-document-action-button"
                                :class="{
                                    disabled: !enableChooseContactButton,
                                }"
                                @click="chooseContactModal"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'user']" />
                                </template>
                            </BaseButton>
                            <BaseButton
                                :buttonText="duplicateButtonText"
                                class="blue-button modal-order-document-action-button"
                                @click="duplicateOrderDocument"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'clone']" />
                                </template>
                            </BaseButton>
                            <BaseButton
                                :buttonText="downloadButtonText"
                                class="blue-button modal-order-document-action-button"
                                @click="showCreateAmendment"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'file-pdf']" />
                                </template>
                            </BaseButton>
                            <BaseButton
                                :buttonText="sendButtonText"
                                class="blue-button modal-order-document-action-button"
                                :class="{
                                    disabled: !enableSendButton,
                                }"
                                @click="openMailReaderDocument"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'envelope']" />
                                </template>
                            </BaseButton>
                            <BaseButton
                                :buttonText="refuseButtonText"
                                class="blue-button modal-order-document-action-button"
                                :class="{
                                    disabled: !enableRefuseButton,
                                }"
                                @click="refuseOrderDocument"
                                v-if="displayRefuseButton"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'times']" />
                                </template>
                            </BaseButton>
                            <BaseButton
                                :buttonText="validateButtonText"
                                class="blue-button modal-order-document-action-button"
                                :class="{
                                    disabled: !enableValidateButton,
                                }"
                                @click="openOrderDocumentValidationModal"
                                v-if="displayValidateButton"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </template>
                            </BaseButton>
                            <BaseButton
                                :buttonText="deleteButtonText"
                                class="blue-button modal-order-document-action-button"
                                :class="{
                                    disabled: !enableDeleteButton,
                                }"
                                @click="openOrderDocumentDeleteModal"
                                v-if="displayDeleteButton"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'trash']" />
                                </template>
                            </BaseButton>
                            <br><br>
                            <BaseSelect
                                v-if="displayRibSelect"
                                v-model="orderDocument.customerRib"
                                label="RIB"
                                name="rib"
                                fieldValue="id"
                                fieldLabel="name"
                                :searchable="true"
                                api="rib/search"
                                @onChange="onInputChange('rib')"
                                :minChars="2"
                            >
                            </BaseSelect>
                        </div>
                        <div>
                            <BaseButton
                                @click="saveOrderDocumentPrompt"
                                :buttonText="saveButtonText"
                                class="orange-button modal-order-document-action-button"
                                :class="{
                                    disabled: !enableSaveButton,
                                }"
                                :disabled="disableSaveButton"
                            >
                                <template v-slot:iconBefore>
                                    <font-awesome-icon :icon="['fas', 'save']" />
                                </template>
                            </BaseButton>
                        </div>
                    </div>
                    <div id="modal-order-document-detail">
                        <div id="modal-order-document-page">
                            <HeaderOrderDocument
                                v-model:orderDocument="orderDocument"
                                :orderDocumentTitle="orderDocumentTitle"
                                :establishment="orderDocument?.establishment"
                            />
                            <div id="order-document-body">
                                <div id="order-document-subtitle">
                                    <BaseDatepicker
                                        class="date-picker"
                                        v-if="orderDocument?.createdAt"
                                        v-model="orderDocument.createdAt"
                                        @change="updateDocumentCustomDate"
                                    />
                                </div>
                                <div id="order-document-address-main-container">
                                    <div>
                                        <div class="order-document-address-title">ADRESSE DE FACTURATION</div>
                                        <div class="order-document-address-container">
                                            <div class="order-document-address-line-container">
                                                <div>
                                                    <font-awesome-icon :icon="['fas', 'user']" />Nom
                                                </div>
                                                <BaseInput
                                                    :autoWidth="true"
                                                    type="text"
                                                    v-model="orderDocument.billingCustomerName"
                                                    validator="text"
                                                    style="font-weight: bold;"
                                                />
                                            </div>
                                            <div class="order-document-address-line-container">
                                                <div>
                                                    <font-awesome-icon
                                                        :icon="['fas', 'map-marker-alt']"
                                                    />Adresse
                                                </div>
                                                <div>
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.billingAddress"
                                                        validator="text"
                                                    />
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.billingAddress2"
                                                        validator="text"
                                                    />
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.billingAddress3"
                                                        validator="text"
                                                    />
                                                    <div>
                                                        <BaseInput
                                                            :autoWidth="true"
                                                            type="text"
                                                            v-model="orderDocument.billingZipCode"
                                                            validator="text"
                                                        />
                                                        <BaseInput
                                                            :autoWidth="true"
                                                            type="text"
                                                            v-model="orderDocument.billingTown"
                                                            validator="text"
                                                        />
                                                    </div>
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.billingCountry"
                                                        validator="text"
                                                    />
                                                </div>
                                            </div>
                                            <div class="order-document-address-line-container">
                                                <div>
                                                    <font-awesome-icon :icon="['fas', 'phone-alt']" />Téléphone
                                                </div>
                                                <BaseInput
                                                    :autoWidth="true"
                                                    type="text"
                                                    v-model="orderDocument.billingPhoneNumber"
                                                    validator="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="order-document-address-title">ADRESSE DE LIVRAISON</div>
                                        <div class="order-document-address-container">
                                            <div class="order-document-address-line-container">
                                                <div>
                                                    <font-awesome-icon :icon="['fas', 'user']" />Nom
                                                </div>
                                                <BaseInput
                                                    :autoWidth="true"
                                                    type="text"
                                                    v-model="orderDocument.deliveryCustomerName"
                                                    validator="text"
                                                    style="font-weight: bold;"
                                                />
                                            </div>
                                            <div class="order-document-address-line-container">
                                                <div>
                                                    <font-awesome-icon
                                                        :icon="['fas', 'map-marker-alt']"
                                                    />Adresse
                                                </div>
                                                <div>
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.deliveryAddress"
                                                        validator="text"
                                                    />
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.deliveryAddress2"
                                                        validator="text"
                                                    />
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.deliveryAddress3"
                                                        validator="text"
                                                    />
                                                    <div>
                                                        <BaseInput
                                                            :autoWidth="true"
                                                            type="text"
                                                            v-model="orderDocument.deliveryZipCode"
                                                            validator="text"
                                                        />
                                                        <BaseInput
                                                            :autoWidth="true"
                                                            type="text"
                                                            v-model="orderDocument.deliveryTown"
                                                            validator="text"
                                                        />
                                                    </div>
                                                    <BaseInput
                                                        :autoWidth="true"
                                                        type="text"
                                                        v-model="orderDocument.deliveryCountry"
                                                        validator="text"
                                                    />
                                                </div>
                                            </div>
                                            <div class="order-document-address-line-container">
                                                <div>
                                                    <font-awesome-icon :icon="['fas', 'phone-alt']" />Téléphone
                                                </div>
                                                <BaseInput
                                                    :autoWidth="true"
                                                    type="text"
                                                    v-model="orderDocument.deliveryPhoneNumber"
                                                    validator="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="order-document-contact-information-container">
                                    <div>
                                        <div class="order-document-contact-information-title">Représentant</div>
                                        <div class="order-document-contact-information-detail">
                                            <BaseInput
                                                :autoWidth="true"
                                                type="text"
                                                v-model="orderDocument.representingFirstname"
                                                validator="text"
                                            />
                                            <BaseInput
                                                :autoWidth="true"
                                                type="text"
                                                v-model="orderDocument.representingLastname"
                                                validator="text"
                                            />
                                        </div>
                                        <div class="order-document-contact-information-detail">-
                                            <BaseInput
                                                :autoWidth="true"
                                                type="text"
                                                v-model="orderDocument.representingPhoneNumber"
                                                validator="text"
                                            />-
                                            <BaseInput
                                                :autoWidth="true"
                                                type="text"
                                                v-model="orderDocument.representingEmail"
                                                validator="text"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div class="order-document-contact-information-title">À l'attention de</div>
                                        <div class="order-document-contact-information-detail">
                                            <BaseInput
                                                :autoWidth="true"
                                                type="text"
                                                v-model="orderDocument.customerFirstname"
                                                validator="text"
                                            />
                                            <BaseInput
                                                :autoWidth="true"
                                                type="text"
                                                v-model="orderDocument.customerLastname"
                                                validator="text"
                                            />-
                                            <BaseInput
                                                :autoWidth="true"
                                                type="text"
                                                v-model="orderDocument.customerPhoneNumber"
                                                validator="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="orderDocument.type === 'quotation'">
                                    <div>
                                        <OrderDocumentLines
                                            v-model:options="orderDocumentLines.top"
                                            @onLineChanged="computeLines"
                                        />
                                    </div>
                                    <div>
                                        <OrderDocumentLines
                                            v-model:options="orderDocumentLines.main"
                                            @onLineChanged="computeLines"
                                            @onActionButtonUp="onActionButtonUp"
                                            @onActionButtonDown="onActionButtonDown"
                                            @onActionButtonDelete="onActionButtonDelete"
                                        />
                                    </div>
                                    <div class="small-line">
                                        <BaseInput
                                            :autoWidth="true"
                                            type="text"
                                            v-model="orderDocument.metadata.acceptance"
                                            validator="text"
                                        />
                                    </div>
                                    <div>
                                        <OrderDocumentLines
                                            v-model:options="orderDocumentLines.bottom"
                                            @onLineChanged="computeLines"
                                        />
                                    </div>
                                    <div>
                                        <OrderDocumentLines
                                            v-model:options="orderDocumentLines.bankDetails"
                                            @onLineChanged="computeLines"
                                        />
                                    </div>
                                    <p class="refuse-reason-line" v-if="orderDocument.status === 'refused'">
                                        Devis refusé le {{ format(parse(orderDocument.statusDate, "dd/MM/yyyy HH:mm", new Date()), "EEEE d MMMM yyyy", { locale: fr }) }} pour le motif suivant : {{ orderDocument.refuseReason }}
                                    </p>
                                </div>
                                <div v-if="orderDocument.type === 'arOrder'">
                                    <div>
                                        <OrderDocumentLines
                                            v-model:options="orderDocumentLines.top"
                                            @onLineChanged="computeLines"
                                        />
                                    </div>
                                    <div>
                                        <OrderDocumentLines
                                            v-model:options="orderDocumentLines.main"
                                            @onLineChanged="computeLines"
                                            @onActionButtonUp="onActionButtonUp"
                                            @onActionButtonDown="onActionButtonDown"
                                            @onActionButtonDelete="onActionButtonDelete"
                                        />
                                    </div>
                                    <div>
                                        <OrderDocumentLines
                                            v-model:options="orderDocumentLines.bottom"
                                            @onLineChanged="computeLines"
                                        />
                                    </div>
                                </div>
                                <div class="capital-line">
                                    <BaseInput
                                        :autoWidth="true"
                                        type="text"
                                        v-model="orderDocument.metadata.capital"
                                        validator="text"
                                    />
                                </div>
                            </div>
                            <div v-if="orderDocument.type === 'proForma'">
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.top"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.main"
                                        @onLineChanged="computeLines"
                                        @onActionButtonUp="onActionButtonUp"
                                        @onActionButtonDown="onActionButtonDown"
                                        @onActionButtonDelete="onActionButtonDelete"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.bottom"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.bankDetails"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                            </div>
                            <div v-if="orderDocument.type === 'invoice'">
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.top"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.main"
                                        @onLineChanged="computeLines"
                                        @onActionButtonUp="onActionButtonUp"
                                        @onActionButtonDown="onActionButtonDown"
                                        @onActionButtonDelete="onActionButtonDelete"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.bottom"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                                <div class="condition-line">
                                    <BaseInput
                                        :autoWidth="true"
                                        :auto-height="true"
                                        type="textarea"
                                        v-model="orderDocument.metadata.condition"
                                        validator="text"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.bankDetails"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                            </div>
                            <div v-if="orderDocument.type === 'credit'">
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.top"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.main"
                                        @onLineChanged="computeLines"
                                        @onActionButtonUp="onActionButtonUp"
                                        @onActionButtonDown="onActionButtonDown"
                                        @onActionButtonDelete="onActionButtonDelete"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.bottom"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                            </div>
                            <div v-if="orderDocument.type === 'deliveryForm'">
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.top"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.main"
                                        @onLineChanged="computeLines"
                                        @onActionButtonUp="onActionButtonUp"
                                        @onActionButtonDown="onActionButtonDown"
                                        @onActionButtonDelete="onActionButtonDelete"
                                    />
                                </div>
                                <div>
                                    <OrderDocumentLines
                                        v-model:options="orderDocumentLines.bottom"
                                        @onLineChanged="computeLines"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>

        <Modal :hideIcon="true" v-show="displayAddLineModal" id="modal-add-line">
            <template v-slot:modalTitle>
                <div>Ajouter une ligne</div>
            </template>
            <template v-slot:modalContent>
                <form>
                    <div>
                        <BaseSelect
                            :defaultOptions="lineTypes"
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.lineType?.error?.message"
                            :required="true"
                            @onChange="onAddLineFormInputChange"
                            label="Type de ligne"
                            name="lineType"
                            v-model="form.addLine.lineType"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'article'">
                        <BaseCheckbox
                            v-model="form.addLine.addToNomenclature"
                            label="Ajouter dans la partie nomenclature"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'article'">
                        <BaseSelect
                            v-model="form.addLine.articleEstablishmentFilter"
                            label="Etablissement"
                            :defaultOptions="establishments"
                            name="searchArticleEstablishment"
                            :display-tag="true"
                            fieldLabel="label"
                            fieldValue="id"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'article'">
                        <BaseSelect
                            :searchable="true"
                            @onChange="onArticleLineAdd"
                            api="article/search"
                            :apiParams="{
                                establishment: form.addLine.articleEstablishmentFilter?.value
                            }"
                            fieldLabel="label"
                            fieldValue="id"
                            label="Ajouter un article"
                            v-model="form.addLine.article"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'text'">
                        <BaseSelect
                            :defaultOptions="lineTexts"
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.lineText?.error?.message"
                            :required="true"
                            @onChange="onAddLineFormInputChange"
                            label="Texte à ajouter"
                            name="lineText"
                            v-model="form.addLine.lineText"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'reduction'">
                        <BaseSelect
                            :defaultOptions="lineReductionTypes"
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.lineReductionType?.error?.message"
                            :required="true"
                            @onChange="onAddLineFormInputChange"
                            label="Type de réduction"
                            name="lineReductionType"
                            v-model="form.addLine.lineReductionType"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'manual'">
                        <BaseInput
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.title?.error?.message"
                            @onChange="onAddLineFormInputChange"
                            label="Titre"
                            name="title"
                            type="textarea"
                            :autoHeight="true"
                            v-model="form.addLine.title"
                            style="min-height: 39px"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'manual' || form.addLine.lineType?.value === 'reduction'">
                        <BaseInput
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.label?.error?.message"
                            @onChange="onAddLineFormInputChange"
                            label="Libellé"
                            name="label"
                            type="textarea"
                            :autoHeight="true"
                            v-model="form.addLine.label"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'reduction' && form.addLine.lineReductionType?.value === 'reductionPercent'">
                        <BaseInput
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.percent?.error?.message"
                            @onChange="onAddLineFormInputChange"
                            label="Réduction (en %)"
                            name="percent"
                            type="text"
                            v-model="form.addLine.percent"
                            validator="decimal_2"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'reduction' && form.addLine.lineReductionType?.value === 'reductionSum'">
                        <BaseInput
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.sum?.error?.message"
                            @onChange="onAddLineFormInputChange"
                            label="Somme (en €)"
                            name="sum"
                            type="text"
                            v-model="form.addLine.sum"
                            validator="decimal_2"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'manual'">
                        <BaseCheckbox
                            v-model="form.addLine.withQuantityAndPrice"
                            label="Spécifier une quantité et un prix"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'article' || form.addLine.withQuantityAndPrice">
                        <BaseInput
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.quantity?.error?.message"
                            @onChange="onAddLineFormInputChange"
                            label="Quantité"
                            name="quantity"
                            type="text"
                            v-model="form.addLine.quantity"
                            validator="decimal_2"
                        />
                    </div>
                    <div v-if="form.addLine.lineType?.value === 'article' || form.addLine.withQuantityAndPrice">
                        <BaseInput
                            :displayError="displayAddLineError"
                            :error="addLineFormErrors?.price?.error?.message"
                            @onChange="onAddLineFormInputChange"
                            label="Prix"
                            name="price"
                            type="text"
                            v-model="form.addLine.price"
                            validator="decimal_2"
                        />
                    </div>
                </form>
            </template>
            <template v-slot:modalButtons>
                <BaseButton @click="closeAddLineModal" buttonText="Fermer" class="white-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <BaseButton @click="validateAddLineModal" buttonText="Valider" class="orange-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'check']" />
                    </template>
                </BaseButton>
            </template>
        </Modal>

        <Modal :hideIcon="true" id="modal-send-order-document" v-show="displaySendOrderDocumentModal">
            <template v-slot:modalTitle>
                <div>Envoi du document</div>
            </template>
            <template v-slot:modalContent>
                <form>
                    <div class="text-before-form">{{ form.sendOrderDocumentMessage }}</div>
                    <div>
                        <BaseSelect :defaultOptions="form.contactsCcCurrent" :displayTag="true" :multiple="true" label="Contact(s) en copie" v-model="form.contactCcCurrent" />
                    </div>
                    <div>
                        <BaseInput label="Autre(s) contact(s) en copie, séparés par ;" type="text" v-model="form.otherContacts" />
                    </div>
                </form>
            </template>
            <template v-slot:modalButtons>
                <BaseButton @click="closeSendOrderDocumentModal" buttonText="Annuler" class="white-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <BaseButton @click="validateSendOrderDocumentModal" buttonText="Envoyer" class="orange-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'check']" />
                    </template>
                </BaseButton>
            </template>
        </Modal>

      <MailReader v-show="isModalVisible"
                  mode="affair"
                  :read-only="false"
                  v-model:recipients="value"
                  :attachments="attachments"
                  v-model:title="title"
                  v-model:message="message"
                  :customer-id="orderDocument.customerId"
                  @close-modal="isModalVisible = false"
                  @add-recipient="addRecipient"
      >
        <template v-slot:modalContent>
          <BaseInput
              v-model:model-value="value"
              name="value"
              type="input"
              validator="email"
              is-small
          >
          </BaseInput>

        </template>

        <template v-slot:modalButtons>
          <BaseButton class="orange-button" @click="validateSendOrderDocumentModal" buttonText="Valider">
            <template v-slot:iconBefore>
              <font-awesome-icon :icon="['fas', 'check']" />
            </template>
          </BaseButton>
        </template>

      </MailReader>

        <Modal v-show="displayOrderDocumentValidationModal" :hideIcon="true" >
            <template v-slot:modalTitle>
                VALIDER LE DOCUMENT
            </template>
            <template v-slot:modalContent>
                <div>
                    <form>
                        <div class="text-before-form">Êtes-vous sûr de vouloir valider ce document ?</div>
                        <div>
                            <BaseInput
                                label="Numéro de commande client"
                                v-model="form.orderNumber"
                                type="text"
                                name="orderNumber"
                                :error="orderDocumentValidationFormErrors?.orderNumber?.error?.message"
                                @onChange="onOrderDocumentValidationFormInputChange"
                                :displayError="displayOrderDocumentValidationError"
                                validator="text"
                            />
                        </div>
                        <div>
                            <ListWithDelete v-model="form.documents" label="Pièces jointes" @onDelete="handleDocuments">
                                <template v-slot:content="{ item }">
                                    <BaseFile
                                        v-model="item.path"
                                        name="path"
                                        :files="item.file"
                                        v-on:update:files="item.file = $event"
                                        accept="application/pdf"
                                        @onChange="handleDocuments"
                                        :disabled="!item.editable"
                                    >
                                        <template v-slot:iconBefore>
                                            <font-awesome-icon :icon="['fas', 'file-pdf']" />
                                        </template>
                                    </BaseFile>
                                </template>
                            </ListWithDelete>
                        </div>
                    </form>
                </div>
            </template>
            <template v-slot:modalButtons>
                <BaseButton @click="closeOrderDocumentValidationModal" buttonText="Annuler" class="white-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <BaseButton @click="validateOrderDocumentValidationModal" buttonText="Confirmer" class="orange-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'check']" />
                    </template>
                </BaseButton>
            </template>
        </Modal>

        <Modal :hideIcon="true" v-show="displayChooseContactModal">
            <template v-slot:modalTitle>
                <div>Choix du contact</div>
            </template>
            <template v-slot:modalContent>
                <form>
                    <div>
                        <BaseSelect :defaultOptions="form.contactsCurrent" label="Contact principal" v-model="form.contactCurrent" />
                    </div>
                </form>
            </template>
            <template v-slot:modalButtons>
                <BaseButton @click="closeChooseContactModal" buttonText="Fermer" class="white-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <BaseButton @click="validateChooseContactModal" buttonText="Valider" class="orange-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'check']" />
                    </template>
                </BaseButton>
            </template>
        </Modal>

        <Modal :hideIcon="true" v-show="displayDuplicateModal">
            <template v-slot:modalTitle>
                <div>Dupliquer le document</div>
            </template>
            <template v-slot:modalContent>
                <form>
                    <div>
                        <BaseSelect
                            v-if="duplicateOrderDocumentEstablishment == null"
                            v-model="duplicateOrderDocumentAffair"
                            label="Doit être associé à l'affaire"
                            name="affairId"
                            fieldValue="id"
                            fieldLabel="name"
                            :searchable="true"
                            api="affair/search"
                            :minChars="2"
                        />
                    </div>
                    <div>
                        <BaseSelect
                            v-if="duplicateOrderDocumentAffair == null"
                            v-model="duplicateOrderDocumentEstablishment"
                            label="Établissement"
                            :defaultOptions="establishments"
                        />
                    </div>
                </form>
            </template>
            <template v-slot:modalButtons>
                <BaseButton @click="closeDuplicateModal" buttonText="Fermer" class="white-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <BaseButton @click="validateDuplicateModal" buttonText="Valider" class="orange-button">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'check']" />
                    </template>
                </BaseButton>
            </template>
        </Modal>

        <Modal class="dialog" v-show="displayCreateAmendment">
            <template v-slot:modalTitle>
                <div>Confirmation</div>
            </template>
            <template v-slot:modalContent>
                <div v-if="orderDocument.type === 'invoice'">Voulez-vous enregistrer ce document ?</div>
                <div v-else>Voulez-vous enregistrer ce document et créer un avenant ?</div>
            </template>
            <template v-slot:modalButtons>
                <BaseButton class="white-button" buttonText="Annuler" @click="hideCreateAmendment">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <BaseButton class="orange-button" :buttonText="orderDocument.type === 'invoice' ? 'Enregistrer' : 'Sans avenant'" @click="downloadOrderDocument(false)">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'file-download']" />
                    </template>
                </BaseButton>
                <BaseButton class="orange-button" buttonText="Avec avenant" @click="downloadOrderDocument(true)" v-if="orderDocument.type !== 'invoice'">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'file-pdf']" />
                    </template>
                </BaseButton>
            </template>
        </Modal>

        <Dialog ref="dialog" />
    </div>
</template>

<script>
    import BaseButton from "../../components/Base/BaseButton";
    import BaseInput from "../../components/Base/BaseInput";
    import BaseSelect from "../../components/Base/BaseSelect";
    import BaseFile from "../../components/Base/BaseFile";
    import BaseCheckbox from "../../components/Base/BaseCheckbox";
    import Dialog from "../../components/App/Dialog";
    import Modal from "../../components/App/Modal";
    import ListWithDelete from "../../components/App/ListWithDelete";
    import HeaderOrderDocument from "./HeaderOrderDocument";
    import OrderDocumentLines from "./OrderDocumentLines";
    import { formatDecimal } from '../../utils/formatter';
    import {addDays, format, getDate, getDaysInMonth, parse} from "date-fns";
    import { fr } from "date-fns/locale";
    import changeTypeEnum from '../../enums/changeTypeEnum';
    import axios from 'axios';
    import config from "../../config";
    import { createToast } from 'mosha-vue-toastify';
    import BaseDatepicker from "@/components/Base/BaseDatepicker.vue";
    import MailReader from "@/components/App/MailReader.vue";

    export default {
        name: "OrderDocumentModal",
        props: {
            orderDocuments: {
                type: Array,
                default: []
            },
            orderDocumentId: {
                type: Number,
                default: null
            }
        },
        components: {
          MailReader,
            BaseDatepicker,
            BaseButton,
            BaseInput,
            BaseSelect,
            BaseFile,
            BaseCheckbox,
            Dialog,
            Modal,
            HeaderOrderDocument,
            OrderDocumentLines,
            ListWithDelete
        },
        watch: {
            orderDocuments: {
                handler() {
                    this.getDefaultOrderDocument();
                },
            }
        },
        data() {
            return {
                format: format,
                parse: parse,
                fr: fr,
                orderDocument: null,
                orderDocumentSelected: null,
                orderDocumentTitle: '',
                orderDocumentLines: {},
                displayAddLineError: false,
                displayAddLineModal: false,
                lineTypes: [
                    { label: "Manuelle", value: "manual" },
                    { label: "Article", value: "article" },
                    { label: "Réduction", value: "reduction" },
                    { label: "Texte préformaté", value: "text" }
                ],
                lineReductionTypes: [
                    { label: "Pourcentage", value: "reductionPercent" },
                    { label: "Somme", value: "reductionSum" }
                ],
                displaySendOrderDocumentModal: false,
                displayOrderDocumentValidationModal: false,
                displayOrderDocumentValidationError: false,
                displayChooseContactModal: false,
                addLineFormErrors: {},
                orderDocumentValidationFormErrors: {},
                form: {
                    contactCurrent: null,
                    contactsCurrent: null,
                    contactCcCurrent: null,
                    contactsCcCurrent: null,
                    otherContacts: null,
                    sendOrderDocumentMessage: null,
                    orderNumber: null,
                    documents: [],
                    addLine: {}
                },
                enableAddLineButton: true,
                enableChooseContactButton: true,
                enableSendButton: true,
                displayValidateButton: true,
                enableValidateButton: true,
                displayDeleteButton: true,
                enableDeleteButton: true,
                displayRefuseButton: true,
                enableRefuseButton: true,
                enableSaveButton: true,
                addLineButtonText: "Créer une ligne",
                chooseContactButtonText: "Choix du contact",
                sendButtonText: "Envoyer",
                duplicateButtonText: "Dupliquer",
                downloadButtonText: "Télécharger",
                refuseButtonText: "Refuser",
                validateButtonText: "Valider",
                deleteButtonText: "Supprimer",
                saveButtonText: "Enregistrer",
                disableSaveButton: false,
                lineTexts: [],
                displayDuplicateModal: false,
                displayCreateAmendment: false,
                duplicateOrderDocumentAffair: null,
                duplicateOrderDocumentEstablishment: null,
                establishments: [
                    { label: 'EBC', value: 'ebc' },
                    { label: 'SMG', value: 'smg' }
                ],
                displayRibSelect: false,
                initialBankDetails: null,
                displayMailReaderDocument: null,
                value: null,
                isModalVisible: null,
                title: '',
                message:'',
                contacts: null,
                attachments: null,
            }
        },
        beforeMount() {
            this.getDefaultOrderDocument();
        },
        mounted() {
            this.loadManualInvoiceLines();
        },
        methods: {
            addRecipient(mail){
                this.value.length === 0 ? this.value += mail : this.value += ";"+mail
            },
            getDefaultOrderDocument() {
                if (this.orderDocuments.length > 0) {
                    this.orderDocument = this.orderDocuments.find(orderDocument => orderDocument.id === this.orderDocumentId) ?? this.orderDocuments[0];
                    this.orderDocumentSelected = {
                        label: this.orderDocument.name,
                        value: this.orderDocument.id
                    };
                    this.prepareOrderDocument();
                } else {
                    this.orderDocument = null;
                    this.orderDocumentSelected = null;
                }
            },
            prepareOrderDocument() {
                this.orderDocumentLines = {};

                this.enableAddLineButton = true;
                this.enableChooseContactButton = true;
                this.enableSendButton = true;
                this.displayValidateButton = true;
                this.enableValidateButton = true;
                this.enableDeleteButton = true;
                this.displayRefuseButton = true;
                this.enableRefuseButton = true;
                this.enableSaveButton = true;

                switch (this.orderDocument.type) {
                    case 'quotation':
                        this.orderDocumentTitle = 'OFFRE DE PRIX N°';
                        this.preparerOrderDocumentQuotation();
                    break;
                    case 'invoice':
                        this.orderDocumentTitle = this.orderDocument.number ? 'FACTURE N°' : 'FACTURE';
                        this.preparerOrderDocumentInvoice();
                    break;
                    case 'credit':
                        this.orderDocumentTitle = 'AVOIR N°';
                        this.preparerOrderDocumentCredit();
                    break;
                    case 'deliveryForm':
                        this.orderDocumentTitle = 'BON DE LIVRAISON N°';
                        this.preparerOrderDocumentDeliveryForm();
                    break;
                    case 'arOrder':
                        this.orderDocumentTitle = 'AR DE COMMANDE N°';
                        this.preparerOrderDocumentAR();
                    break;
                    case 'proForma':
                        this.orderDocumentTitle = 'PRO FORMA N°';
                        this.preparerOrderDocumentProForma();
                    break;
                }

                if (this.orderDocument.number) this.orderDocumentTitle += ` ${this.orderDocument.number}`;

                if (this.orderDocument.lastVersion != null && this.orderDocument.lastVersion > 0) {
                    this.orderDocumentTitle += ` - ${this.orderDocument.lastVersion}`;
                }

                this.computeLines();
            },
            onOrderDocumentChoiceChange(input) {
                if (input.changeType === changeTypeEnum.USER) {
                    if (this.orderDocuments.length > 0) {
                        if (this.orderDocumentSelected) {
                            this.orderDocument = this.orderDocuments.find(orderDocument => orderDocument.id === this.orderDocumentSelected.value);
                            this.prepareOrderDocument();
                        }
                    } else {
                        this.orderDocument = null;
                    }
                }
            },
            formateOrderDocument(orderDocument, orderDocumentLines, orderDocumentMainMetadataTables, orderDocumentMetadataTables) {
                let metadata = orderDocument.metadata || {};

                for (let orderDocumentMetadataTable of orderDocumentMetadataTables) {
                    if (orderDocumentMetadataTable == null) continue;

                    if (orderDocumentMetadataTable.lines.length === 1) {
                        for (let orderDocumentMetadataTableKey in orderDocumentMetadataTable.lines[0]) {
                            if (orderDocumentMetadataTable.headers.find(header => header.key === orderDocumentMetadataTableKey)?.isMetadata) {
                                metadata[orderDocumentMetadataTableKey] = orderDocumentMetadataTable.lines[0][orderDocumentMetadataTableKey];
                            }
                        }
                    }
                }

                return {
                    id: orderDocument.id,
                    billingCustomerName: orderDocument.billingCustomerName,
                    billingPhoneNumber: orderDocument.billingPhoneNumber,
                    billingAddress: orderDocument.billingAddress,
                    billingZipCode: orderDocument.billingZipCode,
                    billingTown: orderDocument.billingTown,
                    billingCountry: orderDocument.billingCountry,
                    deliveryCustomerName: orderDocument.deliveryCustomerName,
                    deliveryPhoneNumber: orderDocument.deliveryPhoneNumber,
                    deliveryAddress: orderDocument.deliveryAddress,
                    deliveryZipCode: orderDocument.deliveryZipCode,
                    deliveryTown: orderDocument.deliveryTown,
                    deliveryCountry: orderDocument.deliveryCountry,
                    representingFirstname: orderDocument.representingFirstname,
                    representingLastname: orderDocument.representingLastname,
                    representingPhoneNumber: orderDocument.representingPhoneNumber,
                    representingEmail: orderDocument.representingEmail,
                    customerFirstname: orderDocument.customerFirstname,
                    customerLastname: orderDocument.customerLastname,
                    customerPhoneNumber: orderDocument.customerPhoneNumber,
                    customerRib: orderDocument.customerRib,
                    customerOrderNumber: orderDocument.customerOrderNumber,
                    contactId: orderDocument.contactId,
                    pdfPath: orderDocument.pdfPath,
                    customerId: orderDocument.customerId,

                    lines: orderDocumentLines.map(orderDocumentLine => {
                        let lineMetadata = orderDocumentLine.metadata || {};
                        for (let orderDocumentMainMetadataTable of orderDocumentMainMetadataTables) {
                            if (orderDocumentMainMetadataTable == null) continue;

                            for (let orderDocumentMainMetadataTableKey in orderDocumentLine) {
                                if (orderDocumentMainMetadataTable.headers.find(header => header.key === orderDocumentMainMetadataTableKey)?.isMetadata) {
                                    lineMetadata[orderDocumentMainMetadataTableKey] = orderDocumentLine[orderDocumentMainMetadataTableKey];
                                }
                            }
                        }

                        return {
                            id: orderDocumentLine.id.toString().substr(0, 3) === 'tmp' ? null : orderDocumentLine.id,
                            title: orderDocumentLine.title,
                            label: orderDocumentLine.label,
                            type: orderDocumentLine.type,
                            quantity: this.getValueFormated(orderDocumentLine.quantity),
                            price: this.getValueFormated(orderDocumentLine.price),
                            articleId: orderDocumentLine.articleId,
                            buyPrice: this.getValueFormated(orderDocumentLine.buyPrice),
                            discount: this.getValueFormated(orderDocumentLine.discount),
                            saleAccount: orderDocumentLine.saleAccount,
                            subLines: orderDocumentLine.subLines.map(orderDocumentSubLine => {
                                let subLineMetadata = orderDocumentSubLine.metadata || {};
                                for (let orderDocumentMainMetadataTable of orderDocumentMainMetadataTables) {
                                    if (orderDocumentMainMetadataTable == null) continue;

                                    for (let orderDocumentMainMetadataTableKey in orderDocumentSubLine) {
                                        if (orderDocumentMainMetadataTable.headers.find(header => header.key === orderDocumentMainMetadataTableKey)?.isMetadata) {
                                            subLineMetadata[orderDocumentMainMetadataTableKey] = orderDocumentSubLine[orderDocumentMainMetadataTableKey];
                                        }
                                    }
                                }

                                return {
                                    id: orderDocumentSubLine.id.toString().substr(0, 3) === 'tmp' ? null : orderDocumentSubLine.id,
                                    title: orderDocumentSubLine.title,
                                    label: orderDocumentSubLine.label,
                                    type: orderDocumentSubLine.type,
                                    quantity: this.getValueFormated(orderDocumentSubLine.quantity),
                                    price: this.getValueFormated(orderDocumentSubLine.price),
                                    articleId: orderDocumentSubLine.articleId,
                                    buyPrice: this.getValueFormated(orderDocumentSubLine.buyPrice),
                                    discount: this.getValueFormated(orderDocumentSubLine.discount),
                                    saleAccount: orderDocumentSubLine.saleAccount,
                                    order: orderDocumentSubLine.order,
                                    metadata: subLineMetadata,
                                    delay: orderDocumentSubLine.delay,
                                    deletable: orderDocumentSubLine.deletable,
                                    deleted: orderDocumentSubLine.deleted
                                };
                            }),
                            order: orderDocumentLine.order,
                            metadata: lineMetadata,
                            keep: orderDocumentLine.keep,
                            deletable: orderDocumentLine.deletable,
                            deleted: orderDocumentLine.deleted
                        };
                    }),
                    metadata: metadata,
                    customDate: orderDocument.createdAt
                }
            },
            saveOrderDocumentPrompt() {
                return new Promise((resolve, reject) => {
                    this.$refs.dialog
                    .show({
                        type: "confirm",
                        title: "Confirmation",
                        message: `Êtes-vous sûr de vouloir enregistrer ce document ?`
                    })
                    .then(async () => {
                        await this.saveOrderDocument();
                    })
                    .catch(() => {
                        reject();
                    });
                });
            },
            saveOrderDocument(sendEmit = true) {
                return new Promise((resolve, reject) => {
                    let orderDocumentMainMetadataTables = [];
                    let orderDocumentMetadataTables = [];

                    for (let key of Object.keys(this.orderDocumentLines)) {
                        if (key === 'main') {
                            orderDocumentMainMetadataTables.push(this.orderDocumentLines[key]);
                        } else {
                            orderDocumentMetadataTables.push(this.orderDocumentLines[key]);
                        }
                    }

                    axios
                    .put(`orderDocument/update`, this.formateOrderDocument(this.orderDocument, this.orderDocumentLines.main.lines, orderDocumentMainMetadataTables, orderDocumentMetadataTables), {
                        toastSuccessMessage: `Modification effectuée`,
                        toastError: true,
                        showPreloader: true
                    })
                    .then(() => {
                        if (sendEmit) this.$emit('onOrderDocumentUpdated');
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
                });
            },
            loadManualInvoiceLines() {
                axios
                .get('manualInvoiceLine/list', {
                    showPreloader: true
                })
                .then(response => {
                    this.lineTexts = response.data.map(line => { return { label: line.name, value: line.label } });
                })
                .catch(() => {});
            },
            getContacts(callbackSuccess) {
                axios
                .get(`customer/getContacts?customerId=${this.orderDocument.customerId}`, {
                    showPreloader: true
                })
                .then(response => {
                    this.form.contactsCurrent = response.data.map(contact => {
                        return {
                            label: `${contact.firstname} ${contact.lastname}`,
                            value: contact.id,
                            data: contact
                        };
                    });
                    let contactCurrent = this.form.contactsCurrent.find(contact => contact.value === this.orderDocument.contactId);
                    if (contactCurrent != null) {
                        this.form.contactCurrent = {
                            label: contactCurrent.label,
                            value: contactCurrent.value,
                            data: contactCurrent.data
                        };
                    }
                    if (typeof callbackSuccess === "function") callbackSuccess();
                })
                .catch(() => {});
            },
            getValueFormated(value) {
                if (value != null && value.toString().trim().length > 0 && (value.toString().trim() != '.' || value.toString().trim() != ',')) {
                    return parseFloat(value.toString().replace(/\s/g, ''));
                } else {
                    return null;
                }
            },
            computeLines() {
                if (!this.orderDocumentLines.main) return;

                let totalAmount = 0;
                let marginTotalAmount = 0;

                for (let orderDocumentLine of this.orderDocumentLines.main.lines.filter(orderDocumentLine => !orderDocumentLine.deleted)) {
                    let lineQuantity = this.getValueFormated(orderDocumentLine.quantity);
                    let linePrice = this.getValueFormated(orderDocumentLine.price);
                    let lineBuyPrice = this.getValueFormated(orderDocumentLine.buyPrice);
                    let lineDiscount = this.getValueFormated(orderDocumentLine.discount);
                    orderDocumentLine.buyPriceTot = lineQuantity && lineBuyPrice != null ? lineQuantity * lineBuyPrice : null;

                    if (orderDocumentLine.type !== 'reduction') { // On parcourt les lignes en ignorant les réductions
                        if (lineQuantity != null && linePrice != null) { // Si c'est une ligne classique avec une quantité et un prix
                            // On calcule le coût de la ligne
                            let lineAmount = lineQuantity * linePrice;
                            if (lineDiscount != null) {
                                lineAmount -= lineAmount * (lineDiscount / 100);
                            }
                            orderDocumentLine.amount = formatDecimal(lineAmount, 2, true);
                            totalAmount += lineAmount;

                            if (lineBuyPrice != null) {
                                // On calcule la marge de la ligne
                                let lineMargin = lineQuantity * (linePrice - (linePrice * ((lineDiscount ?? 0) / 100)) - lineBuyPrice);
                                orderDocumentLine.margin = formatDecimal(lineMargin, 2, true);
                                marginTotalAmount += lineMargin;
                            }
                        } else if (orderDocumentLine.subLines != null && orderDocumentLine.subLines.length > 0) { // Si c'est une ligne avec des sous-lignes
                            let lineAmount = null;
                            let lineMargin = null;

                            for (let orderDocumentSubLine of orderDocumentLine.subLines.filter(orderDocumentSubLine => !orderDocumentSubLine.deleted)) {
                                if (orderDocumentSubLine.type === 'article') { // Si ce sont des articles
                                    let subLineQuantity = this.getValueFormated(orderDocumentSubLine.quantity);
                                    let subLinePrice = this.getValueFormated(orderDocumentSubLine.price);
                                    let subLineBuyPrice = this.getValueFormated(orderDocumentSubLine.buyPrice);
                                    let subLineDiscount = this.getValueFormated(orderDocumentSubLine.discount);
                                    orderDocumentSubLine.buyPriceTot = subLineQuantity && subLineBuyPrice != null ? subLineQuantity * subLineBuyPrice : null;

                                    if (subLineQuantity != null && subLinePrice != null) {
                                        // On calcule le coût de la sous-ligne
                                        let subLineAmount = subLineQuantity * subLinePrice;

                                        if (subLineDiscount != null) {
                                            subLineAmount -= subLineAmount * (subLineDiscount / 100);
                                        }
                                        orderDocumentSubLine.amount = formatDecimal(subLineAmount, 2, true);
                                        lineAmount += subLineAmount;

                                        if (subLineBuyPrice != null) {
                                            // On calcule la marge de la sous-ligne
                                            let subLineMargin = subLineQuantity * (subLinePrice - (subLinePrice * ((subLineDiscount ?? 0) / 100)) - subLineBuyPrice);
                                            orderDocumentSubLine.margin = formatDecimal(subLineMargin, 2, true);
                                            lineMargin += subLineMargin;
                                        }
                                    }
                                }
                            }
                            if (lineAmount !== null) {
                                orderDocumentLine.amount = formatDecimal(lineAmount, 2, true);
                                totalAmount += lineAmount;
                            } else {
                                orderDocumentLine.amount = null;
                            }
                            if (lineMargin !== null) {
                                orderDocumentLine.margin = formatDecimal(lineMargin, 2, true);
                                marginTotalAmount += lineMargin;
                            } else {
                                orderDocumentLine.margin = null;
                            }
                        } else {
                            orderDocumentLine.amount = null;
                        }
                    }
                }

                let reductionTotalAmount = 0;
                for (let orderDocumentLine of this.orderDocumentLines.main.lines.filter(orderDocumentLine => !orderDocumentLine.deleted)) {
                    // On parcourt les lignes de réduction
                    if (orderDocumentLine.subLines != null && orderDocumentLine.subLines.length > 0) {
                        let lineAmount = null;
                        for (let orderDocumentSubLine of orderDocumentLine.subLines.filter(orderDocumentSubLine => !orderDocumentSubLine.deleted)) {
                            if (orderDocumentSubLine.type === 'reductionSum' || orderDocumentSubLine.type === 'reductionPercent') {
                                let subLinePrice = this.getValueFormated(orderDocumentSubLine.price);

                                if (subLinePrice != null) {
                                    let reduction = (orderDocumentSubLine.type === 'reductionSum' ? subLinePrice : (totalAmount / 100) * subLinePrice) * orderDocumentSubLine.quantity;

                                    orderDocumentSubLine.amount = formatDecimal(reduction, 2, true);
                                    lineAmount += reduction;
                                }
                            }
                        }
                        if (lineAmount !== null) {
                            orderDocumentLine.amount = formatDecimal(lineAmount, 2, true);
                            reductionTotalAmount += lineAmount;
                        }
                    }
                }

                this.orderDocumentLines.main.total = {
                    amount: totalAmount,
                    marginAmount: marginTotalAmount,
                    reductionAmount: reductionTotalAmount
                };

                this.onComputeLinesEnded();
            },
            duplicateOrderDocument() {
                this.displayDuplicateModal = true;
            },
            showCreateAmendment() {
                if (this.orderDocument.type === 'quotation' && this.orderDocument.status === 'validated') {
                    this.downloadOrderDocument(false, false);
                } else {
                    this.displayCreateAmendment = true;
                }
            },
            hideCreateAmendment() {
                this.displayCreateAmendment = false;
            },
            async downloadOrderDocument(generateAmendment, saveOrderDocument = true) {
                this.hideCreateAmendment();
                if (saveOrderDocument) await this.saveOrderDocument(false);

                if (this.orderDocument.contactId == null) {
                    createToast(`Merci d'associer un contact`, {
                        position: "bottom-right",
                        type: "danger",
                        hideProgressBar: true,
                        showIcon: true,
                        transition: "slide"
                    });
                } else {
                    axios
                    .get(`orderDocument/getLastPDF?id=${this.orderDocument.id}&generateAmendment=${generateAmendment}`, {
                        toastError: true,
                        showPreloader: true
                    })
                    .then(response => {
                        window.open(`${config.BACK_URL}/${config.UPLOAD_DIRECTORY}/${response.data}`, "_blank").focus();
                        this.$emit('onOrderDocumentUpdated');
                    })
                    .catch(() => {});
                }
            },
            refuseOrderDocument() {
                this.$refs.dialog
                .show({
                    type: 'prompt',
                    promptMode: 'input',
                    title: 'Refus du document',
                    label: 'Motif de refus',
                    message: 'Merci de spécifier le motif de refus de ce document'
                })
                .then(input => {
                    if (input == null || input.trim().length === 0) {
                        createToast(
                            `Merci de saisir le motif de refus`, {
                                position: "bottom-right",
                                type: "danger",
                                hideProgressBar: true,
                                showIcon: true,
                                transition: "slide"
                            }
                        );
                    } else {
                        axios
                        .put(`orderDocument/refuse/${this.orderDocument.id}`, {
                            reason: input
                        }, {
                            toastSuccessMessage: 'Le document a été refusé',
                            toastError: true,
                            showPreloader: true
                        })
                        .then(() => {
                            this.$refs.dialog
                            .show({
                                type: 'confirm',
                                title: 'Nouveau devis',
                                message: 'Souhaitez-vous créer un nouveau devis ?'
                            })
                            .then(() => {
                                axios
                                .post(`orderDocument/create`, {
                                    mode: 'quotation',
                                    affairId: this.orderDocument.affairId,
                                    isEmpty: true
                                }, {
                                    toastError: true,
                                    showPreloader: true
                                })
                                .then(() => {
                                    this.$emit('onOrderDocumentUpdated');
                                })
                                .catch(() => {
                                    this.$emit('onOrderDocumentUpdated');
                                });
                            })
                            .catch(() => {
                                this.$emit('onOrderDocumentUpdated');
                            });
                        })
                        .catch(() => {});
                    }
                })
                .catch(() => {});
            },
            onArticleLineAdd(article) {
                if (article.changeType === changeTypeEnum.USER && article.value != null) {
                    this.form.addLine.id = article.value.data.id;
                    this.form.addLine.price = article.value.data.salePrice;
                    this.form.addLine.buyPrice = article.value.data.buyPrice;
                    this.form.addLine.saleAccount = article.value.data.saleAccount;
                    this.form.addLine.isOnQuotation = article.value.data.isOnQuotation;
                }
            },
            onAddLineFormInputChange(input) {
                if (input.error?.message !== null) {
                    this.addLineFormErrors[input.name] = input;
                } else {
                    delete this.addLineFormErrors[input.name];
                }
            },
            openAddLineModal() {
                this.form.addLine = {
                    lineType: {
                        value: "manual",
                        label: "Manuelle"
                    },
                    lineText: null,
                    lineReductionType: {
                        value: "reductionPercent",
                        label: "Pourcentage"
                    },
                    label: null,
                    line: null,
                    article: null,
                    quantity: "1.00",
                    percent: "10.00",
                    sum: "10.00",
                    id: null,
                    price: "1.00",
                    buyPrice: null,
                    saleAccount: null,
                    addToNomenclature: true
                };

                this.displayAddLineModal = true;
            },
            closeAddLineModal() {
                this.displayAddLineModal = false;
                this.displayAddLineError = false;
            },
            validateAddLineModal() {
                switch (this.form.addLine.lineType.value) {
                    case 'manual':
                        this.orderDocumentLines.main.lines.push({
                            id: `tmp_${this.orderDocumentLines.main.lines.length}`,
                            title: this.form.addLine.title,
                            label: this.form.addLine.label,
                            type: 'text',
                            subLines: [],
                            price: this.form.addLine.withQuantityAndPrice ? this.form.addLine.price : null,
                            quantity: this.form.addLine.withQuantityAndPrice ? this.form.addLine.quantity : null,
                            order: this.orderDocumentLines.main.lines.length > 0 ? this.orderDocumentLines.main.lines[this.orderDocumentLines.main.lines.length - 1].order + 1 : 1,
                            deletable: true,
                            deleted: false
                        });
                    break;
                    case 'article':
                        let articleLabel = this.form.addLine.article.data?.quotationLabel?.trim() ?? '';
                        if (articleLabel.length === 0) articleLabel = this.form.addLine.article.data?.label;

                        if (this.form.addLine.addToNomenclature) {
                            let indexArticle = this.orderDocumentLines.main.lines.findIndex(orderDocumentLine => orderDocumentLine.subLines != null && orderDocumentLine.type === 'article');

                            if (indexArticle !== -1) {
                                this.orderDocumentLines.main.lines[indexArticle].subLines.push({
                                    id: `tmp_${this.orderDocumentLines.main.lines[indexArticle].subLines.length}`,
                                    articleId: this.form.addLine.article.value,
                                    label: articleLabel,
                                    type: 'article',
                                    price: this.form.addLine.price,
                                    articleId: this.form.addLine.id,
                                    isOnQuotation: this.form.addLine.isOnQuotation,
                                    buyPrice: this.form.addLine.buyPrice,
                                    saleAccount: this.form.addLine.saleAccount,
                                    quantity: this.form.addLine.quantity,
                                    order: this.orderDocumentLines.main.lines[indexArticle].subLines[this.orderDocumentLines.main.lines[indexArticle].subLines.length - 1].order + 1,
                                    deletable: true,
                                    deleted: false
                                });
                            } else {
                                this.orderDocumentLines.main.lines.push({
                                    id: `tmp_${this.orderDocumentLines.main.lines.length}`,
                                    title: 'De notre fourniture :',
                                    label: 'Montant de la prestation',
                                    type: 'article',
                                    subLines: [{
                                        id: `tmp_0`,
                                        articleId: this.form.addLine.article.value,
                                        label: articleLabel,
                                        type: 'article',
                                        price: this.form.addLine.price,
                                        articleId: this.form.addLine.id,
                                        isOnQuotation: this.form.addLine.isOnQuotation,
                                        buyPrice: this.form.addLine.buyPrice,
                                        saleAccount: this.form.addLine.saleAccount,
                                        quantity: this.form.addLine.quantity,
                                        order: 0,
                                        deletable: true,
                                        deleted: false
                                    }],
                                    order: this.orderDocumentLines.main.lines.length > 0 ? this.orderDocumentLines.main.lines[this.orderDocumentLines.main.lines.length - 1].order + 1 : 1,
                                    deletable: true,
                                    deleted: false
                                });
                            }
                        } else {
                            this.orderDocumentLines.main.lines.push({
                                id: `tmp_${this.orderDocumentLines.main.lines.length}`,
                                articleId: this.form.addLine.article.value,
                                title: articleLabel,
                                label: '',
                                type: 'text',
                                subLines: [],
                                price: this.form.addLine.price,
                                quantity: this.form.addLine.quantity,
                                isOnQuotation: this.form.addLine.isOnQuotation,
                                buyPrice: this.form.addLine.buyPrice,
                                saleAccount: this.form.addLine.saleAccount,
                                order: this.orderDocumentLines.main.lines.length > 0 ? this.orderDocumentLines.main.lines[this.orderDocumentLines.main.lines.length - 1].order + 1 : 1,
                                deletable: true,
                                deleted: false
                            });
                        }
                    break;
                    case 'reduction':
                        let indexReduction = this.orderDocumentLines.main.lines.findIndex(orderDocumentLine => orderDocumentLine.subLines != null && orderDocumentLine.type === 'reduction');

                        if (indexReduction !== -1) {
                            this.orderDocumentLines.main.lines[indexReduction].subLines.push({
                                id: `tmp_${this.orderDocumentLines.main.lines[indexReduction].subLines.length}`,
                                label: this.form.addLine.label,
                                type: this.form.addLine.lineReductionType.value,
                                quantity: 1,
                                isOnQuotation: true,
                                price: this.form.addLine.lineReductionType.value === 'reductionPercent' ? this.form.addLine.percent : this.form.addLine.sum,
                                order: this.orderDocumentLines.main.lines[indexReduction].subLines[this.orderDocumentLines.main.lines[indexReduction].subLines.length - 1].order + 1,
                                deletable: true,
                                deleted: false
                            });
                        } else {
                            this.orderDocumentLines.main.lines.push({
                                id: `tmp_${this.orderDocumentLines.main.lines.length}`,
                                title: 'Remises :',
                                label: 'Montant des remises',
                                type: 'reduction',
                                subLines: [{
                                    id: `tmp_0`,
                                    label: this.form.addLine.label,
                                    type: this.form.addLine.lineReductionType.value,
                                    quantity: 1,
                                    isOnQuotation: true,
                                    price: this.form.addLine.lineReductionType.value === 'reductionPercent' ? this.form.addLine.percent : this.form.addLine.sum,
                                    order: 0,
                                    deletable: true,
                                    deleted: false
                                }],
                                order: this.orderDocumentLines.main.lines.length > 0 ? this.orderDocumentLines.main.lines[this.orderDocumentLines.main.lines.length - 1].order + 1 : 1,
                                deletable: true,
                                deleted: false
                            });
                        }
                    break;
                    case 'text':
                        this.orderDocumentLines.main.lines.push({
                            id: `tmp_${this.orderDocumentLines.main.lines.length}`,
                            label: this.form.addLine.lineText.value,
                            type: 'text',
                            subLines: [],
                            quantity: null,
                            price: null,
                            order: this.orderDocumentLines.main.lines.length > 0 ? this.orderDocumentLines.main.lines[this.orderDocumentLines.main.lines.length - 1].order + 1 : 1,
                            deletable: true,
                            deleted: false
                        });
                    break;
                }

                this.closeAddLineModal();

                this.computeLines();
            },
          openMailReaderDocument(){
            this.isModalVisible = true;
            axios
                .get(`orderDocument/getDetailBeforeSend?id=${this.orderDocument.id}`, {
                  toastError: true,
                  showPreloader: true
                })
                .then(response => {
                  this.attachments = [{path: config.BACK_URL + "/public/upload/"+this.orderDocument.pdfPath+".pdf"}]
                  let text = ""
                  switch (this.orderDocument.type) {
                    case 'quotation':
                      this.title = `Votre devis N°${this.orderDocument.number}`;
                      text = `notre devis N°${this.orderDocument.number}.\n`
                      break;
                    case 'invoice':
                      this.title = `Votre facture N°${this.orderDocument.number}`;
                      text = `notre facture N°${this.orderDocument.number}.\n`
                      break;
                    case 'deliveryForm':
                      this.title = `Votre bon de livraison N°${this.orderDocument.number}`;
                      text = `notre bon de livraison N°${this.orderDocument.number}.\n`
                      break;
                    case 'arOrder':
                      this.title = `Votre AR de commande N°${this.orderDocument.number}`;
                      text = `notre AR de commande N°${this.orderDocument.number}.\n`
                      break;
                    case 'proForma':
                      this.title = `Votre Pro Forma N°${this.orderDocument.number}`;
                      text = `notre Pro Forma N°${this.orderDocument.number}.\n`
                      break;
                  }

                  this.message = "Bonjour,\n" +
                      "\n" +
                      `Veuillez trouver en pièce jointe ${text}` +
                      "\n" +
                      "Nous vous remercions pour votre confiance.\n" +
                      "\n" +
                      "Bien cordialement,"


                  this.getContacts(() => {
                    this.form.contactsCcCurrent = this.form.contactsCurrent?.filter(contact => contact.value !== response.data.contact.id).map(contact => {
                      return {
                        value: contact.value,
                        label: contact.label
                      };
                    });
                    this.form.contactCcCurrent = this.form.contactsCcCurrent;
                    this.value = response.data.contact.email
                  })
                })
          },
          openSendOrderDocumentModal(){
            axios
                .get(`orderDocument/getDetailBeforeSend?id=${this.orderDocument.id}`, {
                  toastError: true,
                  showPreloader: true
                })
                .then(response => {
                  let text = '';

                  switch (this.orderDocument.type) {
                    case 'quotation':
                      text = `ce devis d'un montant de ${formatDecimal(response.data.amount, 2, true)}€`;
                      break;
                    case 'invoice':
                      text = `cette facture d'un montant de ${formatDecimal(response.data.amount, 2, true)}€`;
                      break;
                    case 'deliveryForm':
                      text = `ce bon de livraison`;
                      break;
                    case 'arOrder':
                      text = `cet AR de commande`;
                      break;
                    case 'proForma':
                      text = `cette facture Pro Forma d'un montant de ${formatDecimal(response.data.amount, 2, true)}€`;
                      break;
                  }

                  this.form.sendOrderDocumentMessage = `Êtes-vous sûr de vouloir envoyer ${text} à ${response.data.contact.email} ?`;
                  this.getContacts(() => {
                    this.form.contactsCcCurrent = this.form.contactsCurrent?.filter(contact => contact.value !== response.data.contact.id).map(contact => {
                      return {
                        value: contact.value,
                        label: contact.label
                      };
                    });

                    this.form.contactCcCurrent = this.form.contactsCcCurrent;
                  });
                  this.displaySendOrderDocumentModal = true;
                })
                .catch(() => {});
          },
          checkMailValid(){
            let notValidMail = 0
            let contacts = this.value?.split(";")
            contacts.forEach( contact => {
              if (!contact.trim().match(/^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+){1,}$/g)){
                notValidMail++
              }
            })
            return notValidMail

          },
          toastNotValidMail(){
            createToast(`Merci d'entrer des adresses mail valides !`, {
              position: "bottom-right",
              type: "danger",
              hideProgressBar: true,
              showIcon: true,
              transition: "slide"
            });
          },
            closeSendOrderDocumentModal() {
                this.displaySendOrderDocumentModal = false;
                this.isModalVisible = false;
                this.form.contactsCcCurrent = [];
            },
            validateSendOrderDocumentModal() {
              this.contacts = this.value.split(";")
              if(this.checkMailValid() > 0 ) {
                this.toastNotValidMail()
              }
              else{
                let text = '';

                switch (this.orderDocument.type) {
                  case 'quotation':
                    text = `Devis envoyé`;
                    break;
                  case 'invoice':
                    text = `Facture envoyée`;
                    break;
                  case 'credit':
                    text = `Avoir envoyé`;
                    break;
                  case 'deliveryForm':
                    text = `Bon de livraison envoyé`;
                    break;
                  case 'arOrder':
                    text = `AR de commande envoyé`;
                    break;
                  case 'proForma':
                    text = `Facture Pro Forma envoyée`;
                    break;
                }

                if (this.contacts == null || this.contacts?.length === 0) {
                  createToast(`Merci de sélectionner au moins un destinataire`, {
                    position: "bottom-right",
                    type: "danger",
                    hideProgressBar: true,
                    showIcon: true,
                    transition: "slide"
                  });
                }

                axios
                    .put(`orderDocument/send`, {
                      id: this.orderDocument.id,
                      recipients: this.value.split(";"),
                      title: this.title,
                      message: this.message
                    }, {
                      toastSuccessMessage: text,
                      toastError: true,
                      showPreloader: true
                    })
                    .then(() => {
                        this.$emit('onOrderDocumentUpdated');
                        this.closeSendOrderDocumentModal();
                    })
                    .catch(() => {});
              }
            },
            openOrderDocumentValidationModal() {
                if (this.orderDocument.type === 'quotation') {
                    this.handleDocuments();
                    this.displayOrderDocumentValidationModal= true;
                } else {
                    this.$refs.dialog
                    .show({
                        type: "confirm",
                        title: "Confirmation",
                        message: `Êtes-vous sûr de vouloir valider ce document ?`
                    })
                    .then(() => {
                        axios
                        .put(`orderDocument/validate/${this.orderDocument.id}`, null, {
                            toastSuccessMessage: 'Le document a été validé',
                            toastError: true,
                            showPreloader: true
                        })
                        .then(() => {
                            this.$emit('onOrderDocumentUpdated');
                        })
                        .catch(() => {});
                    })
                    .catch(() => {});
                }
            },
            openOrderDocumentDeleteModal() {
                this.$refs.dialog
                .show({
                    type: "confirm",
                    title: "Confirmation",
                    message: `Êtes-vous sûr de vouloir supprimer ce document ?`
                })
                .then(() => {
                    axios
                    .put(`orderDocument/delete/${this.orderDocument.id}`, null, {
                        toastSuccessMessage: 'Le document a été supprimé',
                        toastError: true,
                        showPreloader: true
                    })
                    .then(() => {
                        this.$emit('onOrderDocumentUpdated');
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            },
            closeOrderDocumentValidationModal() {
                this.form.orderNumber = null;
                this.form.documents = [];
                this.displayOrderDocumentValidationModal= false;
            },
            formateOrderDocumentValidation(orderDocument) {
                let formData = new FormData();
                if (orderDocument?.id) formData.append('orderDocumentId', orderDocument.id);
                if (this.form.orderNumber) formData.append('orderNumber', this.form.orderNumber);
                this.form.documents.filter(document => document.id == null && document.file?.length > 0).map(document => formData.append('documents', document.file[0]));
                return formData;
            },
            validateOrderDocumentValidationModal() {
                if (!this.checkOrderDocumentValidationForm()) return;

                axios
                .put(`orderDocument/validate`, this.formateOrderDocumentValidation(this.orderDocument), {
                    toastSuccessMessage: `Le document a été validé`,
                    toastError: true,
                    showPreloader: true
                })
                .then(() => {
                    this.closeOrderDocumentValidationModal();
                    this.$emit('onOrderDocumentUpdated');
                })
                .catch(() => {});
            },
            onOrderDocumentValidationFormInputChange(input) {
                if (input.error.message !== null) {
                    this.orderDocumentValidationFormErrors[input.name] = input;
                } else {
                    delete this.orderDocumentValidationFormErrors[input.name];
                }
            },
            checkOrderDocumentValidationForm() {
                this.displayOrderDocumentValidationError = true;
                return Object.keys(this.orderDocumentValidationFormErrors).length === 0;
            },
            handleDocuments() {
                let documents = [];
                let size = 0;

                for (let document of this.form.documents) {
                    if (document.file === null) {
                        documents.push({
                            ...document,
                            hide: false
                        });
                        continue;
                    }

                    if (document.file.length <= 0) continue;

                    size += document.file[0].size / 1000;

                    if (size >= 9000) {
                        createToast(`La taille totale des pièces jointes ne doit pas excéder 9Mo`, {
                            position: 'bottom-right',
                            type: 'danger',
                            hideProgressBar: true,
                            showIcon: true,
                            transition: 'slide'
                        });

                        break;
                    }

                    documents.push({
                        ...document,
                        hide: false
                    });
                }

                documents.push({
                    name: null,
                    description: null,
                    path: 'Ajouter un document',
                    file: [],
                    editable: true,
                    hide: true
                });

                this.form.documents = documents;
            },
            chooseContactModal() {
                this.getContacts(() => this.displayChooseContactModal = true);
            },
            closeChooseContactModal() {
                this.displayChooseContactModal = false;
                this.form.contactsCurrent = [];
            },
            validateChooseContactModal() {
                if (this.form.contactCurrent) {
                    this.orderDocument.customerFirstname = this.form.contactCurrent.data.firstname;
                    this.orderDocument.customerLastname = this.form.contactCurrent.data.lastname;
                    this.orderDocument.customerPhoneNumber = this.form.contactCurrent.data.phoneNumber;
                    this.orderDocument.contactId = this.form.contactCurrent.data.id;

                    this.closeChooseContactModal();
                }
            },
            onActionButtonDelete(slug, type, id) {
                for (let key in this.orderDocumentLines) {
                    if (slug === this.orderDocumentLines[key].slug) {
                        if (type === 'subLine') {
                            for (let line of this.orderDocumentLines[key].lines) {
                                let subLine = line.subLines.find(subLine => subLine.id == id);
                                if (subLine != null && subLine.deletable) {
                                    subLine.deleted = true;
                                    if (line.deletable && !line.subLines.find(subLine => !subLine.deleted)) subLine.deleted = true;
                                }
                            }
                        } else {
                            let line = this.orderDocumentLines[key].lines.find(line => line.id == id);
                            if (line != null && line.deletable) line.deleted = true;
                        }
                        break;
                    }
                }

                if (slug === 'mainLines') this.computeLines();
            },
            onActionButtonUp(slug, type, id) {
                for (let key in this.orderDocumentLines) {
                    if (slug === this.orderDocumentLines[key].slug) {
                        this.moveLine(this.orderDocumentLines[key].lines, type, id, 'up');
                        break;
                    }
                }
            },
            onActionButtonDown(slug, type, id) {
                for (let key in this.orderDocumentLines) {
                    if (slug === this.orderDocumentLines[key].slug) {
                        this.moveLine(this.orderDocumentLines[key].lines, type, id, 'down');
                        break;
                    }
                }
            },
            moveLine(lines, type, id, direction) {
                if (type === 'subLine') {
                    for (let line of lines) {
                        let indexSubLine = line.subLines.findIndex(subLine => subLine.id == id);
                        if (indexSubLine !== -1) {
                            if ((direction === 'up' && indexSubLine > 0) || (direction === 'down' && indexSubLine < line.subLines.length - 1)) {
                                let tmp = JSON.parse(JSON.stringify(line.subLines[direction === 'up' ? indexSubLine - 1 : indexSubLine + 1]));
                                line.subLines[direction === 'up' ? indexSubLine - 1 : indexSubLine + 1].order = line.subLines[indexSubLine].order;
                                line.subLines[indexSubLine].order = tmp.order;
                            }
                            line.subLines.sort((subLine1, subLine2) => subLine1.order < subLine2.order ? -1 : 1);
                            break;
                        }

                        // Parfois, les lignes ont le même order, et de ce fait on ne peut plus trier. Cela permet de solutionner cela
                        for (let sublineIndex = 0; sublineIndex < line.subLines.length; sublineIndex++) {
                            line.subLines[sublineIndex].order = sublineIndex;
                        }
                    }
                } else {
                    let indexLine = lines.findIndex(line => line.id == id);
                    if (indexLine !== -1) {
                        if ((direction === 'up' && indexLine > 0) || (direction === 'down' && indexLine < lines.length - 1)) {
                            let tmp = JSON.parse(JSON.stringify(lines[direction === 'up' ? indexLine - 1 : indexLine + 1]));
                            lines[direction === 'up' ? indexLine - 1 : indexLine + 1].order = lines[indexLine].order;
                            lines[indexLine].order = tmp.order;
                        }
                        lines.sort((line1, line2) => line1.order < line2.order ? -1 : 1);
                    }

                    // Parfois, les lignes ont le même order, et de ce fait on ne peut plus trier. Cela permet de solutionner cela
                    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
                        lines[lineIndex].order = lineIndex;
                    }
                }
            },
            preparerOrderDocumentQuotation() {
                this.sendButtonText = "Envoyer le devis";
                this.duplicateButtonText = "Dupliquer le devis";
                this.downloadButtonText = "Télécharger le devis";
                this.refuseButtonText = "Refuser le devis";
                this.validateButtonText = "Valider le devis";
                this.deleteButtonText = "Supprimer le devis";

                this.displayRibSelect = true;

                if (this.orderDocument.status === 'refused') {
                    this.enableRefuseButton = false;
                }

                if (this.orderDocument.status === 'validated') {
                    this.enableAddLineButton = false;
                    this.enableChooseContactButton = false;
                    this.enableSendButton = false;
                    this.enableValidateButton = false;
                    this.enableDeleteButton = false;
                    this.enableRefuseButton = false;
                    this.enableSaveButton = false;
                    this.displayRibSelect = false;
                }

                if (this.orderDocument.pdfGenerated == false) {
                    this.enableSendButton = false;
                    this.enableValidateButton = false;
                    this.enableDeleteButton = false;
                    this.enableRefuseButton = false;
                }

                this.orderDocumentLines.main = {
                    slug: 'mainLines',
                    titleCanBeNull: true,
                    headers: [{
                        name: 'Libellé',
                        width: '27%',
                        key: 'label',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        canBeNull: true
                    }, {
                        name: 'Quantité',
                        width: '9%',
                        key: 'quantity',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        canBeNull: true
                    }, {
                        name: 'Prix Ach.',
                        width: '9%',
                        key: 'buyPrice',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Prix Ach. Tot.',
                        width: '9%',
                        key: 'buyPriceTot',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Prix Unit.',
                        width: '9%',
                        key: 'price',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Remise (%)',
                        width: '9%',
                        key: 'discount',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Montant',
                        width: '9%',
                        key: 'amount',
                        mode: 'text',
                        subLineClass: 'order-document-cell-not-on-pdf'
                    }, {
                        name: 'Marge',
                        width: '9%',
                        key: 'margin',
                        mode: 'text',
                        class: 'order-document-cell-not-on-pdf',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        postLineClass: 'order-document-cell-not-on-pdf'
                    }],
                    lines: this.orderDocument.lines.map(line => {
                        return {
                            id: line.id,
                            title: line.title,
                            type: line.type,
                            label: line.label,
                            quantity: line.quantity != null ? formatDecimal(line.quantity, 2, true) : null,
                            buyPrice: line.buyPrice != null ? formatDecimal(line.buyPrice, 2, true) : null,
                            price: line.price != null ? formatDecimal(line.price, 2, true) : null,
                            amount: line.quantity != null && line.price != null ? formatDecimal(line.quantity * line.price, 2, true) : null,
                            discount: line.discount != null ? formatDecimal(line.discount, 2, true) : null,
                            margin: line.price != null && line.buyPrice != null ? formatDecimal(line.price - line.buyPrice, 2, true) : null,
                            subLines: line.subLines,
                            saleAccount: line.saleAccount,
                            order: line.order,
                            keep: line.keep,
                            deletable: line.deletable,
                            deleted: line.deleted
                        };
                    }),
                    total: {
                        amount: 0,
                        marginAmount: 0,
                        reductionAmount: 0
                    },
                    actions: {
                        displayKeep: true,
                        deleteLine: this.orderDocument.status !== 'validated',
                        moveLine: this.orderDocument.status !== 'validated',
                        width: '10%'
                    }
                };

                this.orderDocumentLines.top = {
                    slug: 'topLines',
                    headers: [{
                        name: 'N° Compte tiers',
                        width: '40%',
                        key: 'customerAccountNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Votre identifiant TVA',
                        width: '40%',
                        key: 'customerTva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Date de validité',
                        width: '20%',
                        key: 'validityDate',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }],
                    lines: [{
                        customerAccountNumber: this.orderDocument.customerAccountNumber,
                        customerTva: this.orderDocument.metadata.customerTva,
                        tva: this.orderDocument.metadata.tva,
                        amount: '0.00',
                        margin: '0.00'
                    }]
                };

                this.orderDocumentLines.bottom = {
                    slug: 'bottomLines',
                    headers: [{
                        name: 'Conditions de réglement',
                        width: '38%',
                        key: 'paymentTerms',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'TVA',
                        width: '10%',
                        key: 'tva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Montant HT',
                        width: '14%',
                        key: 'amountDf',
                        mode: 'text'
                    }, {
                        name: 'Montant TVA',
                        width: '14%',
                        key: 'amountTva',
                        mode: 'text'
                    }, {
                        name: 'Montant TTC',
                        width: '14%',
                        key: 'amountTi',
                        mode: 'text'
                    }, {
                        name: 'Marge',
                        width: '10%',
                        key: 'margin',
                        mode: 'text',
                        class: 'order-document-cell-not-on-pdf'
                    }],
                    lines: [{
                        validityDate: this.orderDocument.metadata.validityDate,
                        paymentTerms: this.orderDocument.metadata.paymentTerms,
                        tva: this.orderDocument.metadata.tva,
                        amountDf: '0.00 €',
                        amountTva: '0.00 €',
                        amountTi: '0.00 €',
                        margin: '0.00'
                    }]
                };

                this.orderDocumentLines.bankDetails = {
                    slug: 'bankDetailsLines',
                    headers: [{
                        name: 'Coordonnées bancaires',
                        width: '100%',
                        key: 'bankDetails',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }],
                    lines: [{
                        bankDetails: this.orderDocument.metadata.bankDetails
                    }]
                };
            },
            preparerOrderDocumentAR() {
                this.sendButtonText = "Envoyer l'AR";
                this.duplicateButtonText = "Dupliquer l'AR";
                this.downloadButtonText = "Télécharger l'AR";
                this.refuseButtonText = "Refuser l'AR";
                this.validateButtonText = "Valider l'AR";
                this.deleteButtonText = "Supprimer l'AR";

                this.displayValidateButton = false;
                this.displayRefuseButton = false;
                this.displayRibSelect = false;

                if (this.orderDocument.pdfGenerated == false) {
                    this.enableSendButton = false;
                }

                this.orderDocumentLines.main = {
                    slug: 'mainLines',
                    headers: [{
                        name: 'Libellé',
                        width: '45%',
                        key: 'label',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text'
                    }, {
                        name: 'Quantité',
                        width: '15%',
                        key: 'quantity',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        canBeNull: true
                    }, {
                        name: 'Prix Unit.',
                        width: '15%',
                        key: 'price',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Montant',
                        width: '15%',
                        key: 'amount',
                        mode: 'text',
                        subLineClass: 'order-document-cell-not-on-pdf'
                    }],
                    lines: this.orderDocument.lines.map(line => {
                        return {
                            id: line.id,
                            title: line.title,
                            type: line.type,
                            label: line.label,
                            quantity: line.quantity != null ? formatDecimal(line.quantity, 2, true) : null,
                            price: line.price != null ? formatDecimal(line.price, 2, true) : null,
                            amount: line.quantity != null && line.price != null ? formatDecimal(line.quantity * line.price, 2, true) : null,
                            discount: line.discount != null ? formatDecimal(line.discount, 2, true) : null,
                            subLines: line.subLines.map(subLine => {
                                return {
                                    id: subLine.id,
                                    title: subLine.title,
                                    type: subLine.type,
                                    label: subLine.label,
                                    quantity: subLine.quantity != null ? formatDecimal(subLine.quantity, 2, true) : null,
                                    price: subLine.price != null ? formatDecimal(subLine.price, 2, true) : null,
                                    amount: subLine.quantity != null && subLine.price != null ? formatDecimal(subLine.quantity * subLine.price, 2, true) : null,
                                    discount: subLine.discount != null ? formatDecimal(subLine.discount, 2, true) : null,
                                    order: subLine.order,
                                    delay: subLine.metadata.delay,
                                    buyPrice: subLine.buyPrice,
                                    isOnQuotation: subLine.isOnQuotation,
                                    margin: subLine.margin,
                                    saleAccount: subLine.saleAccount,
                                    deletable: subLine.deletable,
                                    deleted: subLine.deleted
                                };
                            }),
                            order: line.order,
                            delay: line.metadata.delay,
                            saleAccount: line.saleAccount,
                            keep: line.keep,
                            deletable: line.deletable,
                            deleted: line.deleted
                        };
                    }),
                    total: {
                        amount: 0,
                        reductionAmount: 0
                    },
                    actions: {
                        displayKeep: true,
                        deleteLine: this.orderDocument.status !== 'validated',
                        moveLine: this.orderDocument.status !== 'validated',
                        width: '10%'
                    }
                };

                this.orderDocumentLines.top = {
                    slug: 'topLines',
                    headers: [{
                        name: 'N° Compte tiers',
                        width: '22%',
                        key: 'customerAccountNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Votre identifiant TVA',
                        width: '30%',
                        key: 'customerTva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° de commande',
                        width: '22%',
                        key: 'customerOrderNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° de devis',
                        width: '26%',
                        key: 'orderDocumentRelatedNumber',
                        mode: 'text'
                    }],
                    lines: [{
                        customerAccountNumber: this.orderDocument.customerAccountNumber,
                        customerTva: this.orderDocument.metadata.customerTva,
                        customerOrderNumber: this.orderDocument.metadata.customerOrderNumber,
                        orderDocumentRelatedNumber: this.orderDocument.orderDocumentRelatedNumber
                    }]
                };

                this.orderDocumentLines.bottom = {
                    slug: 'bottomLines',
                    headers: [{
                        name: 'Conditions de réglement',
                        width: '47%',
                        key: 'paymentTerms',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'TVA',
                        width: '11%',
                        key: 'tva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Montant HT',
                        width: '14%',
                        key: 'amountDf',
                        mode: 'text'
                    }, {
                        name: 'Montant TVA',
                        width: '14%',
                        key: 'amountTva',
                        mode: 'text'
                    }, {
                        name: 'Montant TTC',
                        width: '14%',
                        key: 'amountTi',
                        mode: 'text'
                    }],
                    lines: [{
                        paymentTerms: this.orderDocument.metadata.paymentTerms,
                        tva: this.orderDocument.metadata.tva,
                        amountDf: '0.00 €',
                        amountTva: '0.00 €',
                        amountTi: '0.00 €'
                    }]
                };
            },
            preparerOrderDocumentProForma() {
                this.sendButtonText = "Envoyer le ProForma";
                this.duplicateButtonText = "Dupliquer le ProForma";
                this.downloadButtonText = "Télécharger le ProForma";
                this.refuseButtonText = "Refuser le ProForma";
                this.validateButtonText = "Valider le ProForma";
                this.deleteButtonText = "Supprimer le ProForma";

                this.displayValidateButton = false;
                this.displayRefuseButton = false;
                this.displayRibSelect = false;

                if (this.orderDocument.pdfGenerated == false) {
                    this.enableSendButton = false;
                }

                this.orderDocumentLines.main = {
                    slug: 'mainLines',
                    headers: [{
                        name: 'Libellé',
                        width: '54%',
                        key: 'label',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text'
                    }, {
                        name: 'Quantité',
                        width: '12%',
                        key: 'quantity',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        canBeNull: true
                    }, {
                        name: 'Prix Unit.',
                        width: '12%',
                        key: 'price',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Montant',
                        width: '12%',
                        key: 'amount',
                        mode: 'text',
                        subLineClass: 'order-document-cell-not-on-pdf'
                    }],
                    lines: this.orderDocument.lines.map(line => {
                        return {
                            id: line.id,
                            title: line.title,
                            type: line.type,
                            label: line.label,
                            quantity: line.quantity != null ? formatDecimal(line.quantity, 2, true) : null,
                            price: line.price != null ? formatDecimal(line.price, 2, true) : null,
                            amount: line.quantity != null && line.price != null ? formatDecimal(line.quantity * line.price, 2, true) : null,
                            discount: line.discount != null ? formatDecimal(line.discount, 2, true) : null,
                            subLines: line.subLines,
                            saleAccount: line.saleAccount,
                            order: line.order,
                            keep: line.keep,
                            deletable: line.deletable,
                            deleted: line.deleted
                        };
                    }),
                    total: {
                        amount: 0,
                        reductionAmount: 0
                    },
                    actions: {
                        displayKeep: true,
                        deleteLine: this.orderDocument.status !== 'validated',
                        moveLine: this.orderDocument.status !== 'validated',
                        width: '10%'
                    }
                };

                this.orderDocumentLines.top = {
                    slug: 'topLines',
                    headers: [{
                        name: 'N° Compte tiers',
                        width: '22%',
                        key: 'customerAccountNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Votre identifiant TVA',
                        width: '30%',
                        key: 'customerTva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° de commande',
                        width: '22%',
                        key: 'customerOrderNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° de devis',
                        width: '26%',
                        key: 'orderDocumentRelatedNumber',
                        mode: 'text'
                    }],
                    lines: [{
                        customerAccountNumber: this.orderDocument.customerAccountNumber,
                        customerTva: this.orderDocument.metadata.customerTva,
                        customerOrderNumber: this.orderDocument.metadata.customerOrderNumber,
                        orderDocumentRelatedNumber: this.orderDocument.orderDocumentRelatedNumber
                    }]
                };

                this.orderDocumentLines.bottom = {
                    slug: 'bottomLines',
                    headers: [{
                        name: 'Conditions de réglement',
                        width: '44%',
                        key: 'paymentTerms',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'TVA',
                        width: '20%',
                        key: 'tva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Montant HT',
                        width: '12%',
                        key: 'amountDf',
                        mode: 'text'
                    }, {
                        name: 'Montant TVA',
                        width: '12%',
                        key: 'amountTva',
                        mode: 'text'
                    }, {
                        name: 'Montant TTC',
                        width: '12%',
                        key: 'amountTi',
                        mode: 'text'
                    }],
                    lines: [{
                        paymentTerms: this.orderDocument.metadata.paymentTerms,
                        tva: this.orderDocument.metadata.tva,
                        amountDf: '0.00 €',
                        amountTva: '0.00 €',
                        amountTi: '0.00 €'
                    }]
                };

                this.orderDocumentLines.bankDetails = {
                    slug: 'bankDetailsLines',
                    headers: [{
                        name: 'Coordonnées bancaires',
                        width: '100%',
                        key: 'bankDetails',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }],
                    lines: [{
                        bankDetails: this.orderDocument.metadata.bankDetails
                    }]
                };
            },
            preparerOrderDocumentInvoice() {
                this.sendButtonText = "Envoyer la facture";
                this.duplicateButtonText = "Dupliquer la facture";
                this.downloadButtonText = "Télécharger la facture";
                this.refuseButtonText = "Refuser la facture";
                this.validateButtonText = "Valider la facture";
                this.deleteButtonText = "Supprimer la facture";

                this.displayValidateButton = false;
                this.displayRefuseButton = false;
                this.displayRibSelect = true;
                this.disableSaveButton = this.orderDocument.exported || this.orderDocument.lastSendDate

                if (this.orderDocument.pdfGenerated == false) {
                    this.enableSendButton = false;
                }

                this.orderDocumentLines.main = {
                    slug: 'mainLines',
                    headers: [{
                        name: 'Libellé',
                        width: '35%',
                        key: 'label',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text'
                    }, {
                        name: 'Compte de vente',
                        width: '17%',
                        key: 'saleAccount',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        class: 'order-document-cell-not-on-pdf',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Quantité',
                        width: '12%',
                        key: 'quantity',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        canBeNull: true
                    }, {
                        name: 'Prix Unit.',
                        width: '12%',
                        key: 'price',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Remise (%)',
                        width: '12%',
                        key: 'discount',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Montant',
                        width: '12%',
                        key: 'amount',
                        mode: 'text',
                        subLineClass: 'order-document-cell-not-on-pdf'
                    }],
                    lines: this.orderDocument.lines.map(line => {
                        return {
                            id: line.id,
                            title: line.title,
                            type: line.type,
                            label: line.label,
                            quantity: line.quantity != null ? formatDecimal(line.quantity, 2, true) : null,
                            price: line.price != null ? formatDecimal(line.price, 2, true) : null,
                            amount: line.quantity != null && line.price != null ? formatDecimal(line.quantity * line.price, 2, true) : null,
                            discount: line.discount != null ? formatDecimal(line.discount, 2, true) : null,
                            subLines: line.subLines,
                            saleAccount: line.saleAccount,
                            order: line.order,
                            keep: line.keep,
                            deletable: line.deletable,
                            deleted: line.deleted
                        };
                    }),
                    total: {
                        amount: 0,
                        reductionAmount: 0
                    },
                    actions: {
                        displayKeep: true,
                        deleteLine: this.orderDocument.status !== 'validated',
                        moveLine: this.orderDocument.status !== 'validated',
                        width: '10%'
                    }
                };

                this.orderDocumentLines.top = {
                    slug: 'topLines',
                    headers: [{
                        name: 'N° Compte tiers',
                        width: '20%',
                        key: 'customerAccountNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Votre identifiant TVA',
                        width: '25%',
                        key: 'customerTva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° de commande',
                        width: '20%',
                        key: 'customerOrderNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° d\'offre de prix',
                        width: '22%',
                        key: 'relatedQuotationNumber',
                        mode: 'text',
                        isMetadata: false
                    }, {
                        name: 'N° du BL',
                        width: '18%',
                        key: 'relatedDeliveryFormNumber',
                        mode: 'text',
                        isMetadata: false
                    }, {
                        name: 'Date du BL',
                        width: '20%',
                        key: 'relatedDeliveryFormDate',
                        mode: 'text',
                        isMetadata: false
                    }],
                    lines: [{
                        customerAccountNumber: this.orderDocument.customerAccountNumber,
                        customerTva: this.orderDocument.metadata.customerTva,
                        customerOrderNumber: this.orderDocument.metadata.customerOrderNumber,
                        tva: this.orderDocument.metadata.tva,
                        amount: '0.00',
                        relatedQuotationNumber: this.orderDocument.relatedQuotationNumber,
                        relatedDeliveryFormNumber: this.orderDocument.relatedDeliveryFormNumber,
                        relatedDeliveryFormDate: this.orderDocument.relatedDeliveryFormDate
                    }]
                };

                let bottomHeaders = [];

                if (this.orderDocument.type === 'invoice') {
                    bottomHeaders = [{
                        name: `Date d'échéance`,
                        width: '15%',
                        key: 'dueDate',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Conditions de réglement',
                        width: '34%',
                        key: 'paymentTerms',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'TVA',
                        width: '12%',
                        key: 'tva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Montant HT',
                        width: '13%',
                        key: 'amountDf',
                        mode: 'text'
                    }, {
                        name: 'Montant TVA',
                        width: '13%',
                        key: 'amountTva',
                        mode: 'text'
                    }, {
                        name: 'Montant TTC',
                        width: '13%',
                        key: 'amountTi',
                        mode: 'text'
                    }];
                } else {
                    bottomHeaders = [{
                        name: 'TVA',
                        width: '61%',
                        key: 'tva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Montant HT',
                        width: '13%',
                        key: 'amountDf',
                        mode: 'text'
                    }, {
                        name: 'Montant TVA',
                        width: '13%',
                        key: 'amountTva',
                        mode: 'text'
                    }, {
                        name: 'Montant TTC',
                        width: '13%',
                        key: 'amountTi',
                        mode: 'text'
                    }];
                }

                this.orderDocumentLines.bottom = {
                    slug: 'bottomLines',
                    headers: bottomHeaders,
                    lines: [{
                        dueDate: this.orderDocument.metadata.dueDate?.split('-').reverse().join('/'),
                        validityDate: this.orderDocument.metadata.validityDate,
                        paymentTerms: this.orderDocument.metadata.paymentTerms,
                        tva: this.orderDocument.metadata.tva,
                        amountDf: '0.00 €',
                        amountTva: '0.00 €',
                        amountTi: '0.00 €'
                    }]
                };

                this.orderDocumentLines.bankDetails = {
                    slug: 'bankDetailsLines',
                    headers: [{
                        name: 'Coordonnées bancaires',
                        width: '100%',
                        key: 'bankDetails',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }],
                    lines: [{
                        bankDetails: this.orderDocument.metadata.bankDetails
                    }]
                };
            },
            preparerOrderDocumentCredit() {
                this.sendButtonText = "Envoyer l'avoir";
                this.duplicateButtonText = "Dupliquer l'avoir";
                this.downloadButtonText = "Télécharger l'avoir";
                this.refuseButtonText = "Refuser l'avoir";
                this.validateButtonText = "Valider l'avoir";
                this.deleteButtonText = "Supprimer l'avoir";

                this.displayValidateButton = false;
                this.displayRefuseButton = false;
                this.displayRibSelect = false;

                if (this.orderDocument.pdfGenerated == false) {
                    this.enableSendButton = false;
                }

                this.orderDocumentLines.main = {
                    slug: 'mainLines',
                    headers: [{
                        name: 'Libellé',
                        width: '48%',
                        key: 'label',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text'
                    }, {
                        name: 'Quantité',
                        width: '14%',
                        key: 'quantity',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        canBeNull: true
                    }, {
                        name: 'Prix Unit.',
                        width: '14%',
                        key: 'price',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        subLineClass: 'order-document-cell-not-on-pdf',
                        canBeNull: true
                    }, {
                        name: 'Montant',
                        width: '14%',
                        key: 'amount',
                        mode: 'text',
                        subLineClass: 'order-document-cell-not-on-pdf'
                    }],
                    lines: this.orderDocument.lines.map(line => {
                        return {
                            id: line.id,
                            title: line.title,
                            type: line.type,
                            label: line.label,
                            quantity: line.quantity != null ? formatDecimal(line.quantity, 2, true) : null,
                            price: line.price != null ? formatDecimal(line.price, 2, true) : null,
                            amount: line.quantity != null && line.price != null ? formatDecimal(line.quantity * line.price, 2, true) : null,
                            discount: line.discount != null ? formatDecimal(line.discount, 2, true) : null,
                            subLines: line.subLines,
                            saleAccount: line.saleAccount,
                            order: line.order,
                            keep: line.keep,
                            deletable: line.deletable,
                            deleted: line.deleted
                        };
                    }),
                    total: {
                        amount: 0,
                        reductionAmount: 0
                    },
                    actions: {
                        displayKeep: true,
                        deleteLine: this.orderDocument.status !== 'validated',
                        moveLine: this.orderDocument.status !== 'validated',
                        width: '10%'
                    }
                };

                this.orderDocumentLines.top = {
                    slug: 'topLines',
                    headers: [{
                        name: 'N° Compte tiers',
                        width: '25%',
                        key: 'customerAccountNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'Votre identifiant TVA',
                        width: '50%',
                        key: 'customerTva',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° de commande',
                        width: '25%',
                        key: 'customerOrderNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }],
                    lines: [{
                        customerAccountNumber: this.orderDocument.customerAccountNumber,
                        customerTva: this.orderDocument.metadata.customerTva,
                        customerOrderNumber: this.orderDocument.metadata.customerOrderNumber,
                        tva: this.orderDocument.metadata.tva,
                        amount: '0.00'
                    }]
                };

                this.orderDocumentLines.bottom = {
                    slug: 'bottomLines',
                    headers: [{
                        name: 'Montant',
                        width: '100%',
                        key: 'amountDf',
                        mode: 'text'
                    }],
                    lines: [{
                        tva: this.orderDocument.metadata.tva
                    }]
                };
            },
            preparerOrderDocumentDeliveryForm() {
                this.sendButtonText = "Envoyer le BL";
                this.duplicateButtonText = "Dupliquer le BL";
                this.downloadButtonText = "Télécharger le BL";
                this.refuseButtonText = "Refuser le BL";
                this.validateButtonText = "Valider le BL";
                this.deleteButtonText = "Supprimer le BL";

                this.displayValidateButton = false;
                this.displayRefuseButton = false;
                this.displayRibSelect = false;

                if (this.orderDocument.pdfGenerated == false) {
                    this.enableSendButton = false;
                }

                this.orderDocumentLines.main = {
                    slug: 'mainLines',
                    headers: [{
                        name: 'Libellé',
                        width: '76%',
                        key: 'label',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text'
                    }, {
                        name: 'Quantité',
                        width: '14%',
                        key: 'quantity',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'decimal_2',
                        canBeNull: true
                    }],
                    lines: this.orderDocument.lines.map(line => {
                        return {
                            id: line.id,
                            title: line.title,
                            type: line.type,
                            label: line.label,
                            quantity: line.quantity != null ? formatDecimal(line.quantity, 2, true) : null,
                            price: line.price != null ? formatDecimal(line.price, 2, true) : null,
                            amount: line.quantity != null && line.price != null ? formatDecimal(line.quantity * line.price, 2, true) : null,
                            discount: line.discount != null ? formatDecimal(line.discount, 2, true) : null,
                            subLines: line.subLines,
                            saleAccount: line.saleAccount,
                            order: line.order,
                            keep: line.keep,
                            deletable: line.deletable,
                            deleted: line.deleted
                        };
                    }),
                    total: {
                        amount: 0,
                        reductionAmount: 0
                    },
                    actions: {
                        displayKeep: true,
                        deleteLine: this.orderDocument.status !== 'validated',
                        moveLine: this.orderDocument.status !== 'validated',
                        width: '10%'
                    }
                };

                this.orderDocumentLines.top = {
                    slug: 'topLines',
                    headers: [{
                        name: 'N° Compte tiers',
                        width: '33%',
                        key: 'customerAccountNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° de commande',
                        width: '33%',
                        key: 'customerOrderNumber',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true
                    }, {
                        name: 'N° d\'offre de prix',
                        width: '33%',
                        key: 'relatedQuotationNumber',
                        mode: 'text',
                        isMetadata: false
                    }],
                    lines: [{
                        customerAccountNumber: this.orderDocument.customerAccountNumber,
                        customerOrderNumber: this.orderDocument.metadata.customerOrderNumber,
                        relatedQuotationNumber: this.orderDocument.relatedQuotationNumber
                    }]
                };

                this.orderDocumentLines.bottom = {
                    slug: 'bottomLines',
                    lineHeight: '80px',
                    headers: [{
                        name: 'Date de livraison',
                        width: '30%',
                        key: 'deliveryDate',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true,
                        canBeNull: true
                    }, {
                        name: 'Nom et signature du client',
                        width: '30%',
                        key: 'customerName',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true,
                        canBeNull: true
                    }, {
                        name: `Cachet de l'entreprise`,
                        width: '40%',
                        key: 'companyStamp',
                        mode: 'input',
                        type: 'textarea',
                        validator: 'text',
                        isMetadata: true,
                        canBeNull: true
                    }],
                    lines: [{
                        deliveryDate: this.orderDocument.metadata.deliveryDate,
                        customerName: this.orderDocument.metadata.customerName,
                        companyStamp: this.orderDocument.metadata.companyStamp
                    }]
                };
            },
            onComputeLinesEnded() {
                switch (this.orderDocument.type) {
                    case 'quotation':
                        this.orderDocumentLines.bottom.lines[0].margin = formatDecimal((100 / (this.orderDocumentLines.main.total.amount - this.orderDocumentLines.main.total.marginAmount)) * (this.orderDocumentLines.main.total.amount - (this.orderDocumentLines.main.total.amount - this.orderDocumentLines.main.total.marginAmount)), 2, true) + ' %';
                    case 'invoice':
                    case 'credit':
                    case 'arOrder':
                    case 'proForma':
                        let amountDf = this.orderDocumentLines.main.total.amount - this.orderDocumentLines.main.total.reductionAmount;
                        let amountTva = (parseFloat(this.orderDocumentLines.bottom.lines[0].tva) / 100) * amountDf;
                        this.orderDocumentLines.bottom.lines[0].amountDf = formatDecimal(amountDf, 2, true) + ' €';
                        this.orderDocumentLines.bottom.lines[0].amountTva = formatDecimal(amountTva, 2, true) + ' €';
                        this.orderDocumentLines.bottom.lines[0].amountTi = formatDecimal(amountDf + amountTva, 2, true) + ' €';
                    break;
                }
            },
            closeDuplicateModal() {
                this.duplicateOrderDocumentAffair = null;
                this.duplicateOrderDocumentEstablishment = null;
                this.displayDuplicateModal = false;
            },
            validateDuplicateModal() {
                axios
                .post(`orderDocument/duplicate`, {
                    orderDocumentId: this.orderDocument.id,
                    affairId: this.duplicateOrderDocumentAffair !== null ? this.duplicateOrderDocumentAffair.value : null,
                    establishment: this.duplicateOrderDocumentEstablishment !== null ? this.duplicateOrderDocumentEstablishment.value : null
                }, {
                    toastSuccessMessage: `Le document a été dupliqué`,
                    toastError: true,
                    showPreloader: true
                })
                .then((orderDocumentData) => {
                    this.$loading.show({
                        'background-color': '#000000',
                        'color': '#FFFFFF'
                    });

                    this.closeDuplicateModal();

                    let baseRoute = null;

                    switch (orderDocumentData.data.type) {
                        case 'quotation':
                            baseRoute = 'devis-client';
                        break;
                        case 'arOrder':
                            baseRoute = 'ar-commandes';
                        break;
                        case 'proForma':
                            baseRoute = 'pro-formas';
                        break;
                        case 'deliveryForm':
                            baseRoute = 'bons-livraison';
                        break;
                        case 'invoice':
                        case 'credit':
                            baseRoute = 'factures-client';
                        break;
                    }

                    if (baseRoute !== null) document.location = `/${baseRoute}?action=displayOrderDocument${orderDocumentData.data.orderDocumentId != null ? `&orderDocumentId=${orderDocumentData.data.orderDocumentId}` : ''}${orderDocumentData.data.affairId != null ? `&affairId=${orderDocumentData.data.affairId}` : ''}`;
                })
                .catch(() => {});
            },
            onInputChange(type) {
                switch(type) {
                    case 'rib':
                        let currentValue = this.orderDocumentLines.bankDetails.lines[this.orderDocumentLines.bankDetails.lines.findIndex(line => Object.keys(line)[0] === "bankDetails")].bankDetails;
                        if (this.orderDocument.customerRib?.data) {
                            this.orderDocumentLines.bankDetails.lines[this.orderDocumentLines.bankDetails.lines.findIndex(line => Object.keys(line)[0] === "bankDetails")].bankDetails = this.orderDocument.customerRib?.data?.name + ' - ' + this.orderDocument.customerRib?.data?.value;
                            this.initialBankDetails = currentValue;
                        }
                    break;
                }
            },
            updateDocumentCustomDate() {
                let customDate = parse(this.orderDocument.createdAt, 'dd/MM/yyyy', new Date());
                const dayNumber = getDate(customDate)
                if (dayNumber <= 15) {
                    customDate = addDays(customDate, (15 - dayNumber))
                } else {
                    customDate = addDays(customDate, (getDaysInMonth(customDate) - dayNumber))
                }
                this.$nextTick(() => {
                    this.orderDocument.createdAt = format(customDate, 'dd/MM/yyyy');
                })
            }
        }
    }
</script>
