<style scoped>
#email-attachments-container {
  margin: 10px;
}

#recipients {
  margin: 10px;
  display: flex;
}

.email-attachment-link {
  font-size: 12px;
  margin-right: 15px;
  text-decoration: none;
}

a.email-attachment-link, a.email-attachment-link:visited, a.email-attachment-link:hover, a.email-attachment-link:active {
  color: #0D3B70;
}

#email-attachment-text {
  text-decoration: underline;
  margin-right: 10px;
}

.content {
  margin: 20px;
  background-color: #F1F1F1;
}

.contactsSelectContainer {
  margin-left: 5px;
  margin-right: 25px;
}

#title {
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: #0D3B70;
  margin-top: 25px;
  text-align: center;
}
</style>

<template>
  <Modal>
    <template v-slot:modalIcon>
      <font-awesome-icon :icon="['fas', 'search']" />
    </template>
    <template v-if=readOnly v-slot:modalTitle>
      <div>CONSULTER UN EMAIL</div>
    </template>

    <template v-else v-slot:modalTitle>
      <div>REDIGER UN EMAIL</div>
    </template>

    <template v-slot:modalContent>

      <div v-if="attachments?.length > 0" id="email-attachments-container">
        <span id="email-attachment-text">Pièces jointes:</span>
        <a v-for="attachment in attachments" :href="attachment?.path" target="_blank" class="email-attachment-link" @click="displayDocument(attachment)">{{ attachment?.path.substring(attachment?.path.lastIndexOf('/')+1)}}<br></a>
      </div>

      <div id="recipients">
        <span id="email-attachment-text">Destinataires:</span>
        <slot name="modalContent"></slot>
      </div>

      <div class="contactsSelectContainer">
        <BaseSelect
          :apiParams="{
            customerId:
                customerId,
            supplierId:
                supplierId
          }"
          :minChars="0"
          @onChange="addRecipient"
          :searchable="true"
          :api="mode === 'affair' ? 'contact/listContacts/customer' : 'contact/listContacts/supplier'"
          fieldLabel="all"
          fieldValue="id"
          label="Contacts"
          name="searchContacts"
          v-model="currentContact"
      />
      </div>
      

      <div class="content" style="background: #F1F1F1; display: flex">
        <div v-if="readOnly === false" role="presentation"  style="word-wrap: break-word" class="email-container">
            <div>
              <img :src="`${config.BACK_URL}/public/static/header-mail.png`" width="640" alt="header-mail" border="0" align="center" style="max-width: 100% !important;height: auto !important;margin-left: auto !important;margin-right: auto !important;display: block; width: 100%; background: #FFFFFF; font-size: 15px; line-height: 20px; color: #555555; margin: auto;">
            </div>

              <div style="padding: 0 40px 0 40px;text-align: center;font-size: 15px;line-height: 20px;">
                <div style="font-size: 20px;font-weight: 800;text-transform: uppercase;color: #0D3B70; margin-top: 25px;">
                  <div style="font-size: 16px; color: #6f8db0">Electrobroche Concept</div>
                  <br>
                  {{ title }}
                  <hr style="width: 30%;border: 1px solid #0D3B70;">
                </div>
              </div>

              <div style="padding: 0 40px 0 40px; text-align: justify; font-size: 15px; line-height: 20px;">
                <BaseInput
                    :model-value="message"
                    type="textarea"
                    name="username"
                    validator="text"
                    @input="$emit('update:message', $event.target.value)"
                >
                </BaseInput>
              </div>
        </div>

        <table v-if="readOnly === true" role="presentation" bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0" align="center" width="600" style="word-wrap: break-word" class="email-container">
          <div v-html="htmlContent"></div>
        </table>
      </div>
    </template>
    <template v-slot:modalButtons>
      <div class="modal-buttons" v-if="$slots.modalButtons">
        <slot name="modalButtons"></slot>
      </div>

      <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
        <template v-slot:iconBefore>
          <font-awesome-icon :icon="['fas', 'times']" />
        </template>
      </BaseButton>
  </template>
  </Modal>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton.vue";
import Modal from '../../components/App/Modal'
import config from "@/config";
import BaseInput from "@/components/Base/BaseInput.vue";
import BaseSelect from "@/components/Base/BaseSelect.vue";
import {createToast} from "mosha-vue-toastify";

export default {
  name: "MailReader",
  computed: {
    config() {
      return config
    }
  },
  components: {
    BaseSelect,
    BaseInput,
    BaseButton,
    Modal
  },
  data() {
    return {
      displayModalMailReader : true,
      currentContact: null,
    }
  },
  props: {
    mode: {
      type: String,
      default: ""
    },
    recipients: {
      type: String,
      default: ""
    },
    hiddenRecipients: {
      type: Array,
      default: [],
    },
    attachments: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: "",
    },
    htmlContent: {
      type: String,
      default: ""
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    customerId:{
      type: Number,
      default: null
    },
    supplierId:{
      type: Number,
      default: null
    }
  },
  methods: {
    addRecipient(event){

      this.$nextTick(() => {
        this.currentContact = null;
      });

      let mail = event?.value?.data?.email
      const equal = (element) => element === mail

      if(event?.value != null){
        this.recipients.split(";").some(equal) ? createToast(`Le contact est déjà sélectionné`, {
          position: "bottom-right",
          type: "danger",
          hideProgressBar: true,
          showIcon: true,
          transition: "slide"
        }) : this.$emit('add-recipient',mail)
      }
    },
    closeModal() {
      this.$emit('close-modal')
    },
    displayDocument(event) {
      window.open(`${config.BACK_URL}/${event.path}`, '_blank').focus();
    },
  }
}
</script>

