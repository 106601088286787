<style scoped>

</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Créer un bon de livraison" @click="displayCreateDeliveryFormModal">>
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <Aggrid
        apiRoute='orderDocument/list'
        :apiParams="filters"
        :columnDefs="columnDefs"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="((event) => editDeliveryForm(event))"
    >
    </Aggrid>
    <OrderDocumentCreateModal
        mode="deliveryForm"
        orderDocumentRelatedType="invoice"
        title="CRÉER UN BON DE LIVRAISON"
        subtitleDocumentFrom="Basé sur la facture"
        :displayModal="displayModalCreateDeliveryForm"
        @onOrderDocumentCreated="onDeliveryFormCreated"
        @onClose="() => { displayModalCreateDeliveryForm = false }"
    ></OrderDocumentCreateModal>
    <OrderDocumentModal
        v-if="orderDocuments"
        v-model:orderDocuments="orderDocuments"
        v-show="displayOrderDocumentModal"
        :orderDocumentId="currentDeliveryFormId"
        @onClose="closeOrderDocumentModal"
        @onOrderDocumentUpdated="onDeliveryFormUpdated"
    ></OrderDocumentModal>
</template>

<script>
    import CustomTopbar from '../../components/Topbar/CustomTopbar';
    import BaseButton from '../../components/Base/BaseButton'
    import BaseSelect from '../../components/Base/BaseSelect'
    import Modal from '../../components/App/Modal'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import ListDeliveryFormsActionButtonsRenderer from './ListDeliveryFormsActionButtonsRenderer'
    import axios from 'axios'
    import OrderDocumentModal  from '../OrderDocument/OrderDocumentModal';
    import OrderDocumentCreateModal from '../OrderDocument/OrderDocumentCreateModal';
    import ListAffairsActionButtonsRenderer from "@/views/Affair/ListAffairsActionButtonsRenderer.vue";
    import ListTextWithIconRenderer from "@/components/Aggrid/ListTextWithIconRenderer.vue";

    export default {
        name: 'DeliveryForms',
        components: {
            CustomTopbar,
            BaseButton,
            BaseSelect,
            Modal,
            Aggrid,
            ListDeliveryFormsActionButtonsRenderer,
            OrderDocumentModal,
            OrderDocumentCreateModal
        },
        data() {
            return {
                columnDefs: [
                    { field: 'createdAt', headerName : `Date`, flex: 1, lockPosition: true, sortable: true, filter: 'agDateColumnFilter' },
                    { field: 'orderDocumentNumber', headerName : `Numéro`, flex: 1, lockPosition: true, sortable: true },
                    { field: 'billedNumber', headerName : `Num. Fac.`, flex: 1, lockPosition: true, sortable: true },
                    { field: 'billedDate', headerName : `Date Fac.`, flex: 1, lockPosition: true, sortable: true },
                    { headerName : `Affaire(s)`, flex: 1, cellRendererFunctionName:'AffairNumberRendererFunction', suppressMenu: true },
                    { field: 'billingName', headerName: `Client`, flex: 1, lockPosition: true, sortable: true },
                    { headerName : `Établissement`, flex: 1, cellRendererFunctionName:'EstablishmentRendererFunction', suppressMenu: true },
                    {
                        headerName: "Facturé",
                        flex: 1,
                        cellRenderer: "ListTextWithIconRenderer",
                        cellRendererParams: {
                            field: "billed",
                            mode: "boolean"
                        },
                        filterParams: {
                            filterOptions: ['equals']
                        }
                    },
                    { headerName : '', width: 130, cellRenderer: 'ListDeliveryFormsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListDeliveryFormsActionButtonsRenderer: ListDeliveryFormsActionButtonsRenderer,
                    ListTextWithIconRenderer: ListTextWithIconRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'AffairNumberRendererFunction',
                        function(param) {
                            return param.data?.affairs?.map(affair => affair.affairNumber).join(',');
                        }
                    ],[
                        'EstablishmentRendererFunction',
                        function(param) {
                            if (param.data?.metadata?.establishment === 'smg') {
                                return `<div>SMG</div>`;
                            } else if (param.data?.metadata?.establishment === 'ebc') {
                                return `<div>EBC</div>`;
                            }
                            return '';
                        }
                    ]
                ]),
                context: null,
                displayModalCreateDeliveryForm: false,
                displayOrderDocumentModal: false,
                filters: {
                    mode: 'deliveryForm'
                },
                orderDocuments: null,
                currentDeliveryFormId: null
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        mounted() {
            if (this.$route.query?.action === 'displayOrderDocument') {
                this.editDeliveryForm({
                    id: parseInt(this.$route.query.orderDocumentId),
                    affairId: parseInt(this.$route.query.affairId)
                });
            }
        },
        methods: {
            displayCreateDeliveryFormModal() {
                this.displayModalCreateDeliveryForm = true;
            },
            onDeliveryFormCreated(orderDocuments) {
                this.displayModalCreateDeliveryForm = false;
                this.orderDocuments = orderDocuments;
                this.displayOrderDocumentModal = true;
                this.emitter.emit('resizeHeight');
            },
            closeOrderDocumentModal() {
                this.displayOrderDocumentModal = false;
            },
            onDeliveryFormUpdated() {
                this.emitter.emit('ag-grid-reload');
            },
            editDeliveryForm(invoice) {
                axios
                .get(`orderDocument/getSameOrderDocuments?mode=deliveryForm&${invoice.affairId ? `affairId=${invoice.affairId}` : `orderDocumentId=${invoice.id}`}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then(result => {
                    this.currentDeliveryFormId = invoice.id;
                    this.onDeliveryFormCreated(result.data);
                })
                .catch(() => {});
            }
        }
    }
</script>