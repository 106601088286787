<style scoped>
    .order-document-table-header {
        display: flex;
        text-transform: uppercase;
        background-color: #0d3b70;
        color: #FFFFFF;
        font-size: 8px;
        font-weight: bold;
        padding: 4px 12px;
        margin-top: 20px;
    }

    .order-document-table-container {
        background-color: #edf0f5;
        padding: 0px 10px;
        margin-bottom: 20px;
    }
    .order-document-table-container .order-document-line {
        padding: 10px 0px;
        font-size: 10px;
        color: rgb(50, 60, 70);
    }

    .order-document-table-container .order-document-line-title {
        color: rgb(50, 60, 70);
        display: flex;
    }

    .order-document-table-container .order-document-line-title > div:first-child {
        font-weight: bold;
    }

    .order-document-table-container .order-document-line-title > div {
        padding-bottom: 5px;
    }

    .order-document-table-container .order-document-line-title :deep(textarea) {
        text-transform: uppercase;
    }

    .order-document-table-container .order-document-line :deep(.inputeo) {
        margin: 0px !important;
        width: 100% !important;
    }

    .order-document-table-container .order-document-line-postline {
        display: flex;
        font-weight: bold;
        color: rgb(50, 60, 70);
    }

    .order-document-table-container .order-document-line-detail {
        display: flex;
        font-size: 10px;
    }

    .order-document-table-container .order-document-line-detail > div {
        width: 100%;
        display: flex;
        padding-bottom: 5px;
    }

    .order-document-table-container .order-document-line-detail > div > div, .order-document-table-container .order-document-line-postline > div {
        padding-right: 10px;
        box-sizing: border-box;
    }

    .order-document-table-container .cell-text {
        padding: 1px;
    }

    .order-document-table-container .order-document-cell-not-on-pdf,
    .order-document-table-container .order-document-cell-not-on-pdf :deep(input),
    .order-document-table-container .order-document-cell-not-on-pdf :deep(textarea) {
        color: #ff6b00;
    }

    .order-document-table-container .order-document-line-title .action-icon-container,
    .order-document-table-container .order-document-line-detail .action-icon-container {
        display: flex;
        visibility: hidden;
    }

    .order-document-table-container .order-document-line-title:hover .action-icon-container,
    .order-document-table-container .order-document-line-detail:hover .action-icon-container {
        visibility: visible;
    }

    .order-document-table-container .action-icon-container div {
        margin-right: 8px;
        color: #0D3B70;
        width: 12px;
    }

    .order-document-table-container .action-icon-container :deep(.checkbox-button-container) {
        padding: 0px;
    }
    .order-document-table-container .action-icon-container :deep(.checkbox-button) {
        width: 16px;
        height: 16px;
    }
</style>

<template>
    <div class="order-document-table-header">
        <div :key="index" v-for="(header, index) in options.headers" :style="'width:' + header.width">{{ header.name }}</div>
        <div v-if="options.actions?.deleteLine || options.actions?.moveLine" :style="'width:' + options.actions?.width"></div>
    </div>
    <div class="order-document-table-container">
        <div :key="index" v-for="(line, index) in options.lines">
            <div class="order-document-line" :style="'height:' + options.lineHeight + ';' + (line.type === 'main' ? 'background-color: #CED7E3; border-radius: 10px;' : '')" v-if="!line.deleted">
                <div class="order-document-line-title" v-if="line.title || options.titleCanBeNull">
                    <div :style="'width: ' + options.headers[0].width">
                        <BaseInput
                            type="textarea"
                            :autoHeight="true"
                            v-model="line.title"
                            validator="text"
                            @input="onLineChanged"
                        />
                    </div>
                    <div :key="index2" v-for="(header, index2) in options.headers" :style="'width: ' + (index2 != 0 ? header.width : 0)">
                        <BaseInput v-if="index2 != 0 && header.mode === 'input' && (line[header.key] != null || header.canBeNull) && (!line.subLines || line.subLines.length === 0)"
                            :type="header.type"
                            :autoHeight="true"
                            v-model="line[header.key]"
                            :class="header.class"
                            :validator="header.validator"
                            @input="onLineChanged"
                        />
                        <div class="cell-text" v-if="index2 != 0 && header.mode === 'text' && (!line.subLines || line.subLines.length === 0)" :class="header.class">{{ line[header.key] }}</div>
                    </div>
                    <div class="action-icon-container" :style="'width: ' + (options.actions?.width ? options.actions.width : '0px')">
                        <div v-if="options.actions?.moveLine"><font-awesome-icon :icon="['fas', 'arrow-up']" @click="onActionButtonUp" data-type="line" :data-id="line.id" v-if="options.actions?.moveLine && index > 0" /></div>
                        <div v-if="options.actions?.moveLine"><font-awesome-icon :icon="['fas', 'arrow-down']" @click="onActionButtonDown" data-type="line" :data-id="line.id" v-if="options.actions?.moveLine && index < options.lines.length - 1" /></div>
                        <div v-if="options.actions?.deleteLine"><font-awesome-icon :icon="['fas', 'trash']" @click="onActionButtonDelete" data-type="line" :data-id="line.id" v-if="options.actions?.deleteLine && line.deletable" /></div>
                        <div v-if="options.actions?.displayKeep && line.type !== 'main'">
                            <BaseCheckbox
                                v-model="line.keep"
                            />
                        </div>
                    </div>
                </div>
                <div class="order-document-line-detail" v-if="options.headers.length > 0 && (!line.subLines || line.subLines.length === 0)">
                    <div :style="'width: ' + options.headers[0].width" v-if="line.title || options.titleCanBeNull">
                        <BaseInput v-if="options.headers[0].mode === 'input' && (line[options.headers[0].key] != null || options.headers[0].canBeNull)"
                            :type="options.headers[0].type"
                            :autoHeight="true"
                            v-model="line[options.headers[0].key]"
                            :class="options.headers[0].class"
                            :validator="options.headers[0].validator"
                            @input="onLineChanged"
                        />
                        <div class="cell-text" v-if="options.headers[0].mode === 'text'" :class="options.headers[0].class">{{ line[options.headers[0].key] }}</div>
                    </div>
                    <div :key="index2" v-for="(header, index2) in options.headers" :style="'width: ' + header.width" v-else>
                        <BaseInput v-if="header.mode === 'input' && (line[header.key] != null || header.canBeNull)"
                            :type="header.type"
                            :autoHeight="true"
                            v-model="line[header.key]"
                            :class="header.class"
                            :validator="header.validator"
                            @input="onLineChanged"
                        />
                        <div class="cell-text" v-if="header.mode === 'text'" :class="header.class">{{ line[header.key] }}</div>
                    </div>
                    <div v-if="options.actions?.deleteLine || options.actions?.moveLine" :style="'width: ' + (options.actions?.width ? options.actions.width : '0px')"></div>
                </div>
                <div v-else>
                    <div class="order-document-line-detail" :key="index2" v-for="(subLine, index2) in line.subLines">
                        <div v-if="!subLine.deleted">
                            <div :key="index3" v-for="(header, index3) in options.headers" :style="'width: ' + header.width">
                                <BaseInput v-if="header.mode === 'input' && (subLine[header.key] != null || header.canBeNull)"
                                    :type="header.type"
                                    :autoHeight="true"
                                    v-model="subLine[header.key]"
                                    :class="(subLine.type !== 'reductionPercent' && subLine.type !== 'reductionSum' ? header.subLineClass + ' ' : '')+ (!subLine.isOnQuotation ? 'order-document-cell-not-on-pdf' : '')"
                                    :validator="header.validator"
                                    @input="onLineChanged"
                                />
                                <div class="cell-text" v-if="header.mode === 'text'" :class="(subLine.type !== 'reductionPercent' && subLine.type !== 'reductionSum' ? header.subLineClass + ' ' : '') + (!subLine.isOnQuotation ? 'order-document-cell-not-on-pdf' : '')">{{ subLine[header.key] }}</div>
                            </div>
                            <div v-if="options.actions?.deleteLine || options.actions?.moveLine" class="action-icon-container" :style="'width: ' + (options.actions?.width ? options.actions.width : '0px')">
                                <div><font-awesome-icon :icon="['fas', 'arrow-up']" @click="onActionButtonUp" data-type="subLine" :data-id="subLine.id" v-if="options.actions?.moveLine && index2 > 0" /></div>
                                <div><font-awesome-icon :icon="['fas', 'arrow-down']" @click="onActionButtonDown" data-type="subLine" :data-id="subLine.id" v-if="options.actions?.moveLine && index2 < line.subLines.length - 1" /></div>
                                <div><font-awesome-icon :icon="['fas', 'trash']" @click="onActionButtonDelete" data-type="subLine" :data-id="subLine.id" v-if="options.actions?.deleteLine && line.deletable" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="order-document-line-postline">
                        <div :key="index2" v-for="(header, index2) in options.headers" :style="'width: ' + header.width">
                            <BaseInput v-if="header.mode === 'input' && line[header.key] != null"
                                :type="header.type"
                                :autoHeight="true"
                                v-model="line[header.key]"
                                :class="header.postLineClass"
                                :validator="header.validator"
                                @input="onLineChanged"
                            />
                            <div class="cell-text" :class="header.postLineClass" v-if="header.mode === 'text'">{{ line[header.key] }}</div>
                        </div>
                        <div v-if="options.actions?.deleteLine || options.actions?.moveLine" :style="'width: ' + (options.actions?.width ? options.actions.width : '0px')"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseInput from "../../components/Base/BaseInput";
import BaseCheckbox from "../../components/Base/BaseCheckbox";

    export default {
        name: "OrderDocumentLines",
        props: {
            options: {
                type: Object,
                default: {}
            }
        },
        components: {
            BaseInput,
            BaseCheckbox
        },
        emits: ['onLineChanged', 'onActionButtonUp', 'onActionButtonDown', 'onActionButtonDelete'],
        methods: {
            onLineChanged() {
                this.$emit('onLineChanged', this.options.slug);
            },
            onActionButtonUp(event) {
                this.$emit('onActionButtonUp', this.options.slug, event.currentTarget.dataset.type, event.currentTarget.dataset.id);
            },
            onActionButtonDown(event) {
                this.$emit('onActionButtonDown', this.options.slug, event.currentTarget.dataset.type, event.currentTarget.dataset.id);
            },
            onActionButtonDelete(event) {
                this.$emit('onActionButtonDelete', this.options.slug, event.currentTarget.dataset.type, event.currentTarget.dataset.id);
            }
        }
    }
</script>