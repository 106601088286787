<style>

</style>

<template>
    <Modal v-show="displayModal === true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
        <template v-slot:modalTitle>
            <div>{{ title }}</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div v-if="mode === 'deliveryForm'">
                    <BaseSelect
                        v-model="form.establishment"
                        :defaultOptions="establishments"
                        label="Établissement"
                        name="establishment"
                        :required="true"
                        :error="formErrors?.establishment?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
                <div v-if="mode === 'deliveryForm'">
                    <BaseSelect
                        v-model="form.customer"
                        label="Client"
                        name="customer"
                        api="customer/search"
                        :apiParams="{
                            establishment: form.establishment?.value,
                        }"
                        fieldValue="id"
                        fieldLabel="name"
                        :searchable="true"
                        :required="true"
                        :error="formErrors?.customer?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :minChars="1"
                    />
                </div>
                <div v-if="mode === 'deliveryForm' && form.establishment && form.customer">
                    <BaseSelect
                        v-model="form.affairCurrent"
                        label="Affaire"
                        api="affair/search"
                        :apiParams="{
                            establishment: form.establishment?.value,
                            billCustomerId: form.customer?.value,
                            displayCustomer: false,
                            withFinalStatus: true,
                            quotationValidated: true
                        }"
                        name="affair"
                        :required="true"
                        :error="formErrors?.affair?.error?.message"
                        @onChange="onAffairAdd"
                        :displayError="displayError"
                        fieldValue="id"
                        fieldLabel="name"
                        :searchable="true"
                        :minChars="2"
                    />
                </div>
                <div v-if="form.customer == null && mode !== 'deliveryForm'">
                    <BaseSelect
                        v-model="form.affair"
                        label="Affaire"
                        api="affair/search"
                        name="affair"
                        :required="true"
                        :error="formErrors?.affair?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        fieldValue="id"
                        fieldLabel="name"
                        :searchable="true"
                        :minChars="2"
                    />
                </div>
                <div v-if="form.affair != null && form.invoiceType?.value !== 'credit' && mode != 'quotation' && mode != 'deliveryForm'">
                    <BaseSelect
                        v-model="form.orderDocumentRelated"
                        :label="subtitleDocumentFrom"
                        name="orderDocumentRelated"
                        :required="true"
                        :error="formErrors?.orderDocumentRelated?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :defaultOptions="orderDocumentsRelated"
                    />
                </div>
                <div v-if="form.affair == null && mode !== 'deliveryForm'">
                    <BaseSelect
                        v-model="form.customer"
                        label="Client"
                        name="customer"
                        api="customer/search"
                        fieldValue="id"
                        fieldLabel="name"
                        :searchable="true"
                        :required="true"
                        :error="formErrors?.customer?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                        :minChars="1"
                    />
                </div>
                <div v-if="mode !== 'deliveryForm' && form.affair == null">
                    <BaseSelect
                        v-model="form.establishment"
                        :defaultOptions="establishments"
                        label="Établissement"
                        name="establishment"
                        :required="true"
                        :error="formErrors?.establishment?.error?.message"
                        @onChange="onFormInputChange"
                        :displayError="displayError"
                    />
                </div>
                <div v-if="mode == 'invoice'">
                    <BaseSelect
                        v-model="form.invoiceType"
                        :defaultOptions="invoiceTypes"
                        label="Type de facture"
                        @onChange="onFormInputChange"
                    />
                </div>
                <div v-if="mode == 'deliveryForm' && form.affairs.length > 0">
                    <ListWithDelete v-model="form.affairs" label="Liste des affaires">
                        <template v-slot:content="{ item }">
                            <div class="article-detail-container">
                                <div>{{ item.label }}</div>
                            </div>
                        </template>
                    </ListWithDelete>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeOrderDocumentCreateModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="validateOrderDocumentCreateModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton';
    import BaseSelect from '../../components/Base/BaseSelect';
    import ListWithDelete from '../../components/App/ListWithDelete';
    import Modal from '../../components/App/Modal';
    import axios from 'axios';

    export default {
        name: 'OrderDocumentCreateModal',
        components: {
            BaseButton,
            BaseSelect,
            ListWithDelete,
            Modal
        },
        props: {
            displayModal: {
                type: Boolean,
                default: false
            },
            mode: {
                type: String,
                default: null
            },
            orderDocumentRelatedType: {
                type: String,
                default: null
            },
            title: {
                type: String,
                default: null
            },
            subtitleDocumentFrom: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                displayError: false,
                formErrors: {},
                orderDocumentsRelated: [],
                invoiceTypes: [
                    { label: 'Facture', value: 'invoice' },
                    { label: 'Avoir', value: 'credit' }
                ],
                establishments: [
                    { label: 'EBC', value: 'ebc' },
                    { label: 'SMG', value: 'smg' }
                ],
                form: {}
            }
        },
        beforeMount() {
            this.resetOrderDocumentCreateModal();
        },
        methods: {
            resetOrderDocumentCreateModal() {
                this.displayError = false;
                this.form = {
                    affair: null,
                    affairs: [],
                    orderDocumentRelated: null,
                    customer: null,
                    establishment: null,
                    invoiceType: this.invoiceTypes[0]
                };
            },
            onFormInputChange(input) {
                if (input.error?.message !== null) {
                    this.formErrors[input.name] = input;
                } else {
                    delete this.formErrors[input.name];
                }

                switch (input.name) {
                    case 'affair':
                        if (this.form.affair) {
                            axios
                            .get(`orderDocument/getRelatedOrderDocuments?affairId=${this.form.affair.value}&mode=${this.orderDocumentRelatedType}`, {
                                toastError: true,
                                showPreloader: true
                            })
                            .then(result => {
                                if (result.data) {
                                    this.orderDocumentsRelated = [{label: '--- Document vierge ---', value: 'new'}].concat(result.data.map(orderDocumentRelated => {
                                        return {
                                            label: orderDocumentRelated.name,
                                            value: orderDocumentRelated.id
                                        }
                                    }));
                                } else {
                                    this.orderDocumentsRelated = [{label: '--- Document vierge ---', value: 'new'}];
                                }
                            })
                            .catch(() => {});
                        }
                    break;
                }

                if (this.mode === 'quotation' || (this.mode === 'invoice' && this.form.invoiceType.value === 'credit')) {
                    delete this.formErrors['orderDocumentRelated'];
                }

                if (this.form.affair != null) {
                    delete this.formErrors['customer'];
                    delete this.formErrors['establishment'];
                } else if (this.form.customer != null) {
                    delete this.formErrors['affair'];
                    delete this.formErrors['quotation'];
                }
            },
            checkOrderDocumentCreateModal() {
                this.displayError = true;

                return Object.keys(this.formErrors).length === 0;
            },
            formateOrderDocument(affair, affairs, orderDocumentRelated, customer, establishment) {
                let orderDocument = {
                    mode: this.mode === 'invoice' ? this.form.invoiceType.value : this.mode
                };

                if (affair) {
                    if (this.mode === 'quotation' || orderDocumentRelated?.value === 'new' || orderDocument.mode === 'credit') {
                        orderDocument = {
                            ...orderDocument,
                            affairId: affair.value,
                            isEmpty: this.mode !== 'quotation'
                        };
                    } else {
                        orderDocument = {
                            ...orderDocument,
                            orderDocumentId: orderDocumentRelated.value
                        };
                    }
                } else if (affairs && affairs.length > 0) {
                    orderDocument = {
                        ...orderDocument,
                        affairIds: affairs.map(affair => affair.id)
                    };
                } else {
                    orderDocument = {
                        ...orderDocument,
                        customerId: customer.value,
                        establishment: establishment.value,
                        isEmpty: true
                    };
                }

                return orderDocument;
            },
            validateOrderDocumentCreateModal() {
                if (!this.checkOrderDocumentCreateModal()) return;

                axios
                .post('orderDocument/create', this.formateOrderDocument(this.form.affair, this.form.affairs, this.form.orderDocumentRelated, this.form.customer, this.form.establishment), {
                    toastSuccessMessage: `Document créé`,
                    toastError: true,
                    showPreloader: true
                })
                .then((result) => {
                    this.emitter.emit('ag-grid-reload');

                    axios
                    .get(`orderDocument/getSameOrderDocuments?mode=${this.mode}&${this.form?.affair?.value ? `affairId=${this.form.affair.value}` : `orderDocumentId=${result.data}`}`, {
                        toastError: true,
                        showPreloader: true
                    })
                    .then(result => {
                        this.resetOrderDocumentCreateModal();
                        this.$emit('onOrderDocumentCreated', result.data);
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            },
            closeOrderDocumentCreateModal() {
                this.resetOrderDocumentCreateModal();
                this.$emit('onClose');
            },
            onAffairAdd(affair) {
                this.$nextTick(() => {
                    this.form.affairCurrent = null;
                });

                // On ajoute l'affaire si elle n'est pas déjà présente
                if (affair.value != null && this.form.affairs.filter(element => element.id === affair.value.value).length === 0) {
                    this.form.affairs.push({
                        label: affair.value.label,
                        id: affair.value.value
                    });
                }
            }
        }
    }
</script>