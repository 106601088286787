<!-- TODO: cacher certains boutons selon le statut et/ou la disponibilité de la fiche ou du rapport -->
<style>
    .badge-messages {
        position: absolute;
        background-color: #DD0000;
        font-size: 11px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        right: -5px;
        bottom: 0px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<template>
    <div v-if="params.data">
        <div class="container-action-button" v-if="params.context.componentParent.$store.getters.isCustomer && params.data.classicAffair">
            <BaseButton
                @click="onOpenQuotationValidation"
                buttonText="" title="Valider/Refuser le devis"
                v-if="params.data.orderDocumentIdToValidate"
            >
                <template v-slot:iconBefore>
                DEV
                </template>
            </BaseButton>
        <BaseButton @click="onOpenExpertAssessmentReport" buttonText="" title="Consulter le rapport d'expertise" :disabled="params.data.expertAssessmentPath == null">
                <template v-slot:iconBefore>
                EX
                </template>
            </BaseButton>
            <BaseButton @click="onOpenQuotation" buttonText="" title="Consulter le devis" :disabled="params.data.quotationPath == null">
                <template v-slot:iconBefore>
                DE
                </template>
            </BaseButton>
            <BaseButton @click="onOpenConformityReport" buttonText="" title="Consulter le PV de conformité" :disabled="params.data.conformityReportPath == null">
                <template v-slot:iconBefore>
                PV
                </template>
            </BaseButton>
            <BaseButton @click="onOpenInvoice" buttonText="" title="Consulter la facture" :disabled="params.data.invoicePath == null">
                <template v-slot:iconBefore>
                FA
                </template>
            </BaseButton>
        </div>
        <div class="container-action-button" v-if="!params.context.componentParent.$store.getters.isCustomer && params.data.classicAffair">
            <BaseButton @click="onDownloadReport" buttonText="" buttonClass="blue-button" title="Télécharger le bilan" v-if="affairFinalReportStatus.includes(params.data.status) && (params.context.componentParent.$store.getters.isAdmin || params.context.componentParent.$store.getters.isWorkshopManager)">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'receipt']" />
                </template>
            </BaseButton>
            <BaseButton @click="onPrintPackagingCard" buttonText="" title="Imprimer la fiche caisse" buttonClass="orange-button" :disabled="params.data.status === affairStatusEnum.PENDING_ADMIN_VALIDATION">
                <template v-slot:iconBefore>
                CA
                </template>
            </BaseButton>
            <BaseButton @click="onOpenAnnex" buttonText="" title="Consulter la nomenclature" buttonClass="dark-blue-button" v-if="params.data.nomenclature?.completed">
                <template v-slot:iconBefore>
                NO
                </template>
            </BaseButton>
            <BaseButton @click="onOpenExpertAssessmentReport" buttonText="" title="Consulter le rapport d'expertise" :disabled="params.data.expertAssessmentPath == null">
                <template v-slot:iconBefore>
                EX
                </template>
            </BaseButton>
            <BaseButton @click="onOpenQuotation" buttonText="" title="Consulter le devis" :disabled="params.data.quotationPath == null">
                <template v-slot:iconBefore>
                DE
                </template>
            </BaseButton>
            <BaseButton @click="onOpenConformityReport" buttonText="" title="Consulter le PV de conformité" :disabled="params.data.conformityReportPath == null">
                <template v-slot:iconBefore>
                PV
                </template>
            </BaseButton>
            <BaseButton @click="onOpenInvoice" buttonText="" title="Consulter la facture" :disabled="params.data.invoicePath == null">
                <template v-slot:iconBefore>
                FA
                </template>
            </BaseButton>

            <BaseButton @click="onOpenMessages" buttonText="" title="Accéder au fil de discussion" buttonClass="green-button">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'comment-check']" />
                </template>
                <template v-slot:badge v-if="params.data.newMessages > 0">
                  <div class="badge-messages">{{ params.data.newMessages }}</div>
                </template>
            </BaseButton>

            <BaseButton @click="onDeleteAffair" buttonText="" title="Supprimer l'affaire" v-if="!params.data.deleted && (params.context.componentParent.$store.getters.isAdmin || params.context.componentParent.$store.getters.isWorkshopManager)">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'trash']" />
                </template>
            </BaseButton>
            <BaseButton @click="onCancelDeleteAffair" buttonText="" title="Restaurer l'affaire" v-if="params.data.deleted && (params.context.componentParent.$store.getters.isAdmin || params.context.componentParent.$store.getters.isWorkshopManager)">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'trash-restore']" />
                </template>
            </BaseButton>
        </div>
        <div class="container-action-button" v-if="!params.context.componentParent.$store.getters.isCustomer && !params.data.classicAffair">
            <BaseButton @click="onDeleteAffair" buttonText="" buttonClass="dark-blue-button" title="Supprimer l'affaire" v-if="!params.data.deleted && (params.context.componentParent.$store.getters.isAdmin || params.context.componentParent.$store.getters.isWorkshopManager)">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'trash']" />
                </template>
            </BaseButton>
            <BaseButton @click="onCancelDeleteAffair" buttonText="" buttonClass="dark-blue-button" title="Restaurer l'affaire" v-if="params.data.deleted && (params.context.componentParent.$store.getters.isAdmin || params.context.componentParent.$store.getters.isWorkshopManager)">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'trash-restore']" />
                </template>
            </BaseButton>
            <BaseButton @click="onRestoreAffair" buttonText="" buttonClass="dark-blue-button" title="Reprendre l'affaire" v-if="!params.data.deleted && (params.context.componentParent.$store.getters.isAdmin || params.context.componentParent.$store.getters.isWorkshopManager)">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </template>
            </BaseButton>
        </div>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import affairStatusEnum from "../../enums/affairStatusEnum"

    export default {
        name: 'ListAffairsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        data() {
            return {
                affairStatusEnum,
                affairFinalReportStatus: [
                    affairStatusEnum.TO_SHIP,
                    affairStatusEnum.TO_BILL,
                    affairStatusEnum.BILLED,
                    affairStatusEnum.CANCELED,
                    affairStatusEnum.RETURN_WITHOUT_REPAIR,
                    affairStatusEnum.SPINDLE_HOTEL,
                    affairStatusEnum.TO_WRECK,
                    affairStatusEnum.NOT_REPAIRABLE
                ]
            }
        },
        methods: {
            onPrintPackagingCard() {
                this.params.context.componentParent.printPackagingCard(this.params.data);
            },
            onOpenAnnex() {
                this.params.context.componentParent.prepareEditAffair(this.params.data.id, "nomenclature");
            },
            onOpenMessages() {
              this.params.context.componentParent.getMessagesAndOpenModal(this.params.data.id);
            },
            onOpenExpertAssessmentReport() {
                this.params.context.componentParent.customerDownloadExpertAssessment(this.params.data.expertAssessmentPath);
            },
            onOpenConformityReport() {
                this.params.context.componentParent.customerDownloadConformityReport(this.params.data.conformityReportPath);
            },
            onOpenQuotation() {
                this.params.context.componentParent.customerDownloadQuotation(this.params.data.quotationPath);
            },
            onOpenInvoice() {
                this.params.context.componentParent.customerDownloadInvoice(this.params.data.invoicePath);
            },
            onOpenQuotationValidation() {
                this.params.context.componentParent.openQuotationResponse(this.params.data.quotationId);
            },
            onDeleteAffair() {
                this.params.context.componentParent.deleteAffair(this.params.data.id, this.params.data.classicAffair);
            },
            onCancelDeleteAffair() {
                this.params.context.componentParent.onCancelDeleteAffair(this.params.data.id, this.params.data.classicAffair);
            },
            onRestoreAffair() {
                this.params.context.componentParent.restoreAffair(this.params.data.id.slice(0, -1));
            },
            onDownloadReport() {
                this.params.context.componentParent.openIndividualFinalReportModal(this.params.data.id);
            }
        }
    }
</script>
