<style scoped>
    #order-document-electrobroche > div:nth-child(1) {
        font-weight: bold;
        font-size: 13px;
        margin-bottom: 5px;
    }

    #order-document-electrobroche > div:nth-child(2) {
        font-size: 11px;
        margin-top: 5px;
    }

    #order-document-electrobroche {
        padding: 8px 20px;
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        color: #ffffff;
    }

    #order-document-electrobroche-address {
        font-size: 11px;
        margin: 15px 4px;
    }

    #order-document-electrobroche-contact {
        font-size: 12px;
        font-weight: bold;
    }

    #order-document-electrobroche-contact svg {
        margin-right: 5px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    #order-document-logo-1 {
        height: 65px;
    }

    #order-document-logo-2 {
        height: 65px;
    }

    #order-document-headband {
        position: absolute;
        left: 0px;
        padding: 10px 10px;
        font-size: 11px;
        text-align: center;
        background-color: #e6ebf0;
        width: 100%;
        box-sizing: border-box;
        margin-top: 15px;
    }

    .header-icon-container {
        background-color: #0D3B70;
        padding: 8px;
        color: #FFFFFF;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px
    }

</style>

<template>
    <div style="display: flex; gap:20px;">
        <div style="border-radius:30px; border: 1px solid #DCE1E6; flex: 1; height:150px; overflow: hidden; display: flex; justify-content: center; align-items: center;">
            <img v-if="establishment === 'ebc'" src="../../assets/images/logo.png" style="width:220px;"/>
            <img v-if="establishment === 'smg'" src="../../assets/images/logoSmg.png" style="width:220px;"/>
        </div>
        <div style="border-radius:30px; border: 1px solid #DCE1E6; flex: 1; height:150px; overflow: hidden;">
            <div style="width: 100%; background-color: #E76A12; color: #FFFFFF; text-transform: uppercase; text-align: center; font-weight: bold; padding:10px; box-sizing: border-box;">{{ orderDocumentTitle }}</div>
            <div style="padding:20px; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; font-size:15px;">
                <div v-if="orderDocument?.affairNumber">Affaire n° : <span style="font-weight: bold;">{{ orderDocument.affairNumber }}</span></div>
                <div v-if="orderDocument?.billingCustomerName">Client : <span style="font-weight: bold;">{{ orderDocument.billingCustomerName }}</span></div>
            </div>
        </div>
        <div style="border-radius:30px; border: 1px solid #DCE1E6; flex: 1; height:150px; overflow: hidden; display: flex; justify-content: center; flex-direction: column; padding:10px; box-sizing: border-box;">
            <div style="display: flex; margin:8px 0px; align-items: center;">
                <div class="header-icon-container">
                    <font-awesome-icon :icon="['fas', 'envelope']"/>
                </div>
                <div style="font-size:13px;">contact@electrobroche-concept.com</div>
            </div>
            <div style="display: flex; margin:8px 0px; align-items: center;">
                <div class="header-icon-container">
                    <font-awesome-icon :icon="['fas', 'phone']"/>
                </div>
                <div style="font-size:13px;">{{ orderDocument.metadata.phoneNumber }}</div>
            </div>
            <div style="display: flex; margin:8px 0px; align-items: center;">
                <div>
                    <div class="header-icon-container">
                        <font-awesome-icon :icon="['fas', 'map-marker-alt']"/>
                    </div>
                </div>
                <div style="font-size:13px;">
                    <div>ZONE INDUSTRIELLE N°1</div>
                    <div>62290 NOEUX-LES-MINES</div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="establishment === 'ebc'" id="order-document-headband">
        Le spécialiste de la réparation de broches et
        éléctrobroches tous types, toutes marques UGV et
        conventionnelles
    </div>
    <div v-if="establishment === 'smg'" id="order-document-headband">
        Construction, rétrofit et maintenance de machines-outils
    </div>
</template>

<script>
    export default {
        name: "HeaderOrderDocument",
        props: {
            orderDocument: {
                type: Object,
                default: null
            },
            orderDocumentTitle: {
                type: String,
                default: ''
            },
            establishment: {
                type: String,
                default: ''
            }
        }
    }
</script>
